import * as React from 'react'

const Research = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={32} height={32} rx={16} fill="#fee9ce" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m20.134 19.392 3.805 3.806a.376.376 0 0 1 0 .532l-.709.709a.376.376 0 0 1-.532 0l-3.806-3.805a.385.385 0 0 1-.11-.267v-.414A6.519 6.519 0 0 1 8 15.02 6.519 6.519 0 0 1 14.52 8.5a6.519 6.519 0 0 1 4.934 10.783h.414c.1 0 .195.037.267.11ZM9.504 15.02a5.014 5.014 0 0 0 5.016 5.015 5.014 5.014 0 0 0 5.015-5.015 5.014 5.014 0 0 0-5.015-5.015 5.014 5.014 0 0 0-5.015 5.015Z"
      fill="#bc7d2c"
    />
  </svg>
)

export default Research
