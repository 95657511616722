import * as React from 'react'

const Communicate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={32} height={32} rx={16} fill="#bfdbfe" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.07 11.314H10.01v3.357a2.79 2.79 0 0 0-1.206.47v-3.787c0-.687.54-1.246 1.206-1.246h12.06c.665 0 1.206.559 1.206 1.246v3.787a2.792 2.792 0 0 0-1.206-.47v-3.357ZM8 22.369c0-1.164.947-2.11 2.11-2.11h.604c1.163 0 2.11.946 2.11 2.11a.603.603 0 0 1-1.206 0 .906.906 0 0 0-.905-.905h-.602a.906.906 0 0 0-.905.905.603.603 0 0 1-1.206 0Zm13.668-6.935a2.01 2.01 0 1 0 0 4.02 2.01 2.01 0 0 0 0-4.02Zm-.804 2.01a.805.805 0 0 0 1.608 0 .805.805 0 0 0-1.608 0Zm1.105 2.814h-.603c-1.163 0-2.11.947-2.11 2.11a.603.603 0 0 0 1.206 0c0-.498.406-.904.904-.904h.604c.498 0 .904.406.904.905a.603.603 0 0 0 1.206 0c0-1.164-.947-2.11-2.11-2.11Zm-9.547-2.814a2.01 2.01 0 1 1-4.02 0 2.01 2.01 0 0 1 4.02 0Zm-2.01-.804a.805.805 0 0 1 0 1.608.805.805 0 0 1 0-1.608Zm5.628 2.814a2.01 2.01 0 1 1 0-4.02 2.01 2.01 0 0 1 0 4.02Zm.804-2.01a.805.805 0 0 1-1.608 0 .805.805 0 0 1 1.608 0Zm-1.106 2.814h.604c1.163 0 2.11.947 2.11 2.11a.603.603 0 0 1-1.206 0 .906.906 0 0 0-.904-.904h-.603a.906.906 0 0 0-.905.905.603.603 0 0 1-1.206 0c0-1.164.947-2.11 2.11-2.11Z"
      fill="#1d4ed8"
    />
  </svg>
)

export default Communicate
