import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import AuthLayoutHeader from '../components/auth/AuthLayoutHeader'
import RegisterForm from '../components/auth/RegisterForm'
import authService from '../services/authService'
import { RegisterTDO, SignInError } from '../types/auth-in'
import { User } from '../types/user'

const schema = Yup.object().shape({
  firstName: Yup.string().required('Fist name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Must be a valid email').required(),
  emailConfirmation: Yup.string()
    .email('Must be a valid email')
    .oneOf([Yup.ref('email')], 'Emails must match')
    .required('Confirm Email is required'),
  password: Yup.string()
    .required()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must 8 characters, at least one letter, one number and one special character'
    ),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),
})

const initialValues: RegisterTDO = {
  firstName: '',
  lastName: '',
  email: '',
  emailConfirmation: '',
  password: '',
  passwordConfirmation: '',
}

const Register: React.FC = () => {
  const register = useMutation<User, SignInError, RegisterTDO>({
    mutationFn: authService.createUserWithEmailAndPassword,
    onError: () => {
      toast.error('Error occurred during the login process.')
    },
  })

  const handleOnSubmit = async (
    values: RegisterTDO,
    formikBag: FormikHelpers<RegisterTDO>
  ): Promise<void> => {
    try {
      await register.mutateAsync(values)

      // navigate('/auth/magic-link')
    } catch (e) {
      const error = e as SignInError
      switch (error.code) {
        case 'auth/email-already-in-use':
          formikBag.setFieldError('email', 'Email already in use')
          break
      }
    }
  }

  return (
    <>
      <AuthLayoutHeader
        title="Create Account"
        subTitle={
          <p className="text-gray-600 mt-4">
            Create an account to keep all your Groups in one place, it'll just
            take a moment.
          </p>
        }
      />

      <div className="mt-6">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting }: FormikProps<RegisterTDO>) => (
            <Form className="space-y-6" noValidate>
              <RegisterForm isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>

        <span className="text-gray-400 mt-6 text-center block">
          Haven an account already?{' '}
          <Link className="text-link" to="/auth/sign-in">
            Login
          </Link>
        </span>
      </div>
    </>
  )
}

export default Register
