import React from 'react'
import Navbar from '../Navbar'

type HomeLayoutProps = {
  children?: React.ReactNode
}

const HomeLayout: React.FC = ({ children }: HomeLayoutProps) => (
  <div className="bg-gray-50 print:bg-white min-h-screen px-4 sm:px-6 lg:px-8">
    <Navbar />
    {children}
  </div>
)

export default HomeLayout
