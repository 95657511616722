import { Navigate } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { userRoles } from '../../utils/config'
import LoadingScreen from '../LoadingScreen'

type AdminGuardProps = {
  children?: React.ReactNode
}

const AdminGuard: React.FC = ({ children }: AdminGuardProps) => {
  const user = useUser()

  if (user.isLoading) return <LoadingScreen />

  if (!user.data) return <Navigate to="/auth/sign-in" />

  if (user.data.role !== userRoles.admin) {
    return <Navigate replace to="/" />
  }

  return <>{children}</>
}

export default AdminGuard
