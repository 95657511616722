import * as React from 'react'

const Reflect = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={32} height={32} rx={16} fill="#fbcfe8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.664 13.586 4.079.595a.894.894 0 0 1 .494 1.524l-2.95 2.876.697 4.062c.126.734-.648 1.281-1.295.94l-3.649-1.917-3.649 1.918a.893.893 0 0 1-1.295-.941l.698-4.062-2.95-2.876a.894.894 0 0 1 .493-1.524l4.079-.595 1.823-3.696c.33-.667 1.276-.659 1.602 0l1.823 3.696Zm.846 8.39-.662-3.864 2.809-2.736-3.88-.564-1.737-3.518-1.736 3.518-3.88.564 2.808 2.736-.662 3.864 3.47-1.823 3.47 1.823Z"
      fill="#be185d"
    />
  </svg>
)

export default Reflect
