import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { Navigate } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import authService from '../../services/authService'
import subscriptionService from '../../services/subscriptionService'
import { queryKeys } from '../../utils/config'
import LoadingScreen from '../LoadingScreen'

type GuestGuardProps = {
  children?: React.ReactNode
}

const GuestGuard: React.FC = ({ children }: GuestGuardProps) => {
  const user = useUser()

  const { data: userSubscription, isLoading } = useQuery({
    queryKey: [queryKeys.stripe.mySubscription],
    queryFn: () => subscriptionService.getFirebaseSubscription(user.data?.id),
    enabled: !!user.data,
  })

  if (isLoading || user.isLoading) return <LoadingScreen />

  if (user.data) {
    if (!userSubscription?.subscription) {
      if (user.data.organisation) {
        // *Should be impossible to dont have a subscription being a organisation
        // *To cover this case the user wont have access to the app and will be redirect to the homepage
        authService.logout()
        return <Navigate replace to="/" />
      }
      return <Navigate replace to="/subscribe" />
    }

    if (
      userSubscription.subscription >= Math.round(new Date().getTime() / 1000)
    ) {
      return <Navigate to="/projects" />
    } else {
      return <Navigate to="/account" />
    }
  }

  return <>{children}</>
}

export default GuestGuard
