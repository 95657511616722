import React from 'react'
import { Link } from 'react-router-dom'
import { Group } from '../../types/groups'

type GroupCardProps = {
  groups: Group[]
}

const GroupCard: React.FC<GroupCardProps> = ({ groups }) => {
  return (
    <div className="grid grid-cols-1 w-full gap-4 place-items-center sm:place-items-start sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {groups.map(({ name, students, id, projectNumber }) => (
        <div
          className="rounded-lg overflow-hidden bg-white w-full max-w-xs shadow"
          key={id}
        >
          <div className="min-h-[104px] items-center p-6 px-6 bg-gray-800 flex">
            <p className="text-lg font-semibold text-white">{name}</p>
          </div>
          <div className="flex flex-col py-6 px-6 gap-4">
            <div className="flex justify-between">
              <p className="font-semibold">Students</p>{' '}
              <p>{students?.length || '-'}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Projects</p> <p>{projectNumber}</p>
            </div>
          </div>
          <div className="py-4 px-6  border-t border-gray-200 flex gap-3">
            <Link
              to={`/groups/${id}`}
              className="btn btn-white block text-center w-full"
            >
              Edit Group
            </Link>
            <Link
              to={`/groups/${id}/marks`}
              className="btn btn-primary block text-center w-full"
            >
              See Marks
            </Link>
            {/* https://assessment-tool.atlassian.net/browse/AT-160 */}
            {/* <button
              className="btn btn-primary block text-center w-full"
              onClick={() => {
                localStorage.setItem('autofill-group', id)
                navigate('/new-project')
              }}
            >
              New Project
            </button> */}
          </div>
        </div>
      ))}
    </div>
  )
}

export default GroupCard
