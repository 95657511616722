import { FastField } from 'formik'
import FormikInput from '../FormikInput'
import Spinner from '../Spinner'

type SignInFormProps = {
  isSubmitting: boolean
}

const SignInForm: React.FC<SignInFormProps> = ({ isSubmitting }) => (
  <>
    <fieldset disabled={isSubmitting}>
      <div className="space-y-3">
        <FastField
          type="text"
          name="firstName"
          component={FormikInput}
          label="First Name"
          autoFocus
        />
      </div>
    </fieldset>

    <fieldset disabled={isSubmitting}>
      <div className="space-y-3">
        <FastField
          type="text"
          name="lastName"
          component={FormikInput}
          label="Last Name"
        />
      </div>
    </fieldset>

    <fieldset disabled={isSubmitting}>
      <div className="space-y-3">
        <FastField
          type="email"
          name="email"
          component={FormikInput}
          label="Email"
          onCopy={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (process.env.NODE_ENV !== 'development') {
              e.preventDefault()
              return false
            }
          }}
        />
      </div>
    </fieldset>

    <fieldset disabled={isSubmitting}>
      <div className="space-y-3">
        <FastField
          type="email"
          name="emailConfirmation"
          component={FormikInput}
          label="Confirm Email"
          onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (process.env.NODE_ENV !== 'development') {
              e.preventDefault()
              return false
            }
          }}
        />
      </div>
    </fieldset>

    <fieldset disabled={isSubmitting}>
      <div className="space-y-3">
        <FastField
          type="password"
          name="password"
          component={FormikInput}
          label="Password"
        />
      </div>
    </fieldset>

    <fieldset disabled={isSubmitting}>
      <div className="space-y-3">
        <FastField
          type="password"
          name="passwordConfirmation"
          component={FormikInput}
          label="Confirm Password"
          onPaste={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (process.env.NODE_ENV !== 'development') {
              e.preventDefault()
              return false
            }
          }}
        />
        <p className="light-text">
          Must contain six characters, at least one letter, one number and one
          special character
        </p>
      </div>
    </fieldset>

    <div>
      <button className="btn btn-primary btn-full" disabled={isSubmitting}>
        {isSubmitting ? (
          <Spinner className="h-4 w-4 trailing-icon" />
        ) : (
          'Create Account'
        )}
      </button>
    </div>
  </>
)

export default SignInForm
