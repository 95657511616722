import { FastField } from 'formik'
import FormikInput from '../FormikInput'

type UserFormProps = {
  isSubmitting: boolean
}

const UserForm: React.FC<UserFormProps> = ({ isSubmitting }) => (
  <>
    <fieldset className="space-y-3 w-full" disabled={isSubmitting}>
      <FastField
        type="text"
        name="firstName"
        component={FormikInput}
        label="First Name"
        autoFocus
      />
    </fieldset>

    <fieldset className="space-y-3 w-full" disabled={isSubmitting}>
      <FastField
        type="text"
        name="lastName"
        component={FormikInput}
        label="Last Name"
      />
    </fieldset>

    <fieldset className="space-y-3 w-full" disabled={isSubmitting}>
      <FastField
        type="email"
        name="email"
        component={FormikInput}
        label="Email"
        onCopy={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (process.env.NODE_ENV !== 'development') {
            e.preventDefault()
            return false
          }
        }}
      />
    </fieldset>

    <button
      type="submit"
      className="btn btn-primary whitespace-nowrap lg:self-start lg:mt-6 btn-full lg:w-auto"
      disabled={isSubmitting}
    >
      Update Details
    </button>
  </>
)

export default UserForm
