import clsx from 'clsx'
import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { subscriptionCollection } from '../../services/firebase/collections'
import subscriptionService from '../../services/subscriptionService'
import { FBSubscription } from '../../types/subscriptions'
import { User } from '../../types/user'
import { queryKeys } from '../../utils/config'
import { queryClient } from '../../utils/tanstack-react-query'
import { formatter } from '../../utils/stripeUtils'

type SubscriptionProps = {
  user: User
}

const Subscription: React.FC<SubscriptionProps> = ({ user }) => {
  const [sub, setSub] = useState<FBSubscription | undefined>(undefined)
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = () =>
      onSnapshot(doc(subscriptionCollection, user.id), doc => {
        if (doc.exists()) {
          setSub(doc.data())
          setIsSubscriptionLoading(false)
        }
      })

    return unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: product, isLoading: isProductLoading } = useQuery({
    queryKey: [queryKeys.stripe.product],
    queryFn: subscriptionService.getProduct,
    select: response => response.data,
  })

  const cancelSub = useMutation({
    mutationFn: subscriptionService.cancelSubscription,
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: [queryKeys.stripe.mySubscription],
      })
      toast.success('Subscription Updated!')
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  const isActive =
    sub?.subscription &&
    sub.subscription >= Math.round(new Date().getTime() / 1000)

  const renderSubscriptionContent = () => {
    if (isActive && !sub.cancelled) {
      return {
        text: 'Renews automatically on',
        btn: (
          <button
            className="text-link-danger"
            onClick={async () => {
              setIsSubscriptionLoading(true)
              await cancelSub.mutateAsync({ cancelAtPeriodEnd: true })
            }}
          >
            Cancel Subscription
          </button>
        ),
      }
    }

    if (isActive && sub.cancelled) {
      return {
        text: 'Account active to',
        btn: (
          <button
            className="text-link"
            onClick={async () => {
              setIsSubscriptionLoading(true)
              await cancelSub.mutateAsync({ cancelAtPeriodEnd: false })
            }}
          >
            Renew
          </button>
        ),
      }
    }

    return {
      text: 'Subscription ended on',
      btn: (
        <Link to="/subscribe" className="text-link">
          Subscribe
        </Link>
      ),
    }
  }

  return (
    <div className="mt-8 card shadow-none border border-gray-200">
      <div>
        <div className="flex items-center border-b border-gray-200 pb-5 mb-5 sm:pb-6 sm:mb-6">
          <p className="mr-3">Subscription</p>{' '}
          <span
            className={clsx({
              'tag positive-tag': isActive,
              'tag negative-tag': !isActive,
              hidden: isSubscriptionLoading,
            })}
          >
            {isActive ? 'Active' : 'Inactive'}
          </span>
          <div
            className={clsx({
              'block h-6 w-20 animate-pulse rounded-xl bg-gray-200':
                isSubscriptionLoading,
              hidden: !isSubscriptionLoading,
            })}
          />
        </div>
        <div
          className={clsx({
            'block h-6 w-32 animate-pulse rounded-xl bg-gray-200 mb-2':
              isProductLoading,
            hidden: !isProductLoading,
          })}
        />
        <div
          className={clsx({
            'block h-4 w-72 animate-pulse rounded-xl bg-gray-200':
              isProductLoading,
            hidden: !isProductLoading,
          })}
        />

        <p
          className={clsx({
            'light-text': true,
            'hidden ': isProductLoading || !product,
          })}
        >
          <span className="page-tile">
            {product && formatter.format(product.price.unit_amount / 100)}
          </span>{' '}
          Annually
        </p>

        <div
          className={clsx({
            'flex items-center mt-2': true,
            hidden: isSubscriptionLoading,
          })}
        >
          <p className="light-text mr-6">
            {renderSubscriptionContent().text}
            <span className="ml-1">
              {sub?.subscription &&
                new Date(sub?.subscription * 1000).toLocaleDateString('en-GB')}
            </span>
          </p>
          {renderSubscriptionContent().btn}
        </div>
      </div>
    </div>
  )
}

export default Subscription
