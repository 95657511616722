import * as React from 'react'

const FailAndFix = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={32} height={32} rx={16} fill="#bbf7d0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.816 13.274c-1.799 0-3.31 1.47-3.309 3.27.001.702.225 1.353.605 1.886a.379.379 0 0 1-.039.49l-.267.266-.271.27a.377.377 0 0 1-.567-.037A4.749 4.749 0 0 1 8 16.517c.012-2.635 2.187-4.75 4.823-4.75h5.73v-2.01c0-.336.406-.504.643-.267l2.764 2.763a.377.377 0 0 1 0 .533l-2.764 2.764a.377.377 0 0 1-.644-.266v-2.01h-5.736Zm11.264 3.288a4.749 4.749 0 0 0-.968-2.901.377.377 0 0 0-.567-.037l-.267.266-.271.27a.379.379 0 0 0-.039.49c.38.532.604 1.184.605 1.886.001 1.799-1.51 3.27-3.309 3.27h-5.736v-2.01a.377.377 0 0 0-.644-.266l-2.764 2.764a.377.377 0 0 0 0 .532l2.764 2.764c.237.237.643.07.643-.266v-2.01h5.73c2.636 0 4.81-2.116 4.823-4.752Z"
      fill="#15803d"
    />
  </svg>
)

export default FailAndFix
