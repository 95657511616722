import { motion, Variant } from 'framer-motion'
import React from 'react'
import { useInView } from 'react-intersection-observer'

const cards = [
  {
    title: 'Group Students',
    description:
      "Create your class list here, by adding your student's names to a group.",
    img: '/img/brua_group.png',
  },
  {
    title: 'Create a Project',
    description: 'Create a project for each challenge you want to assess.',
    img: '/img/brua_project.png',
  },
  {
    title: 'Mark Students',
    description:
      'Once the students in your group have completed their challenge, you can mark their work using our interactive tool.',
    img: '/img/brua_grade.png',
  },
]

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  } as Variant,
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
}

const item = {
  visible: { opacity: 1, y: 0, transition: { duration: 0.4 } } as Variant,
  hidden: { opacity: 0, y: 100 },
}

const ReadyToMark = () => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
  })

  return (
    <section className="mb-24" id="benefits">
      <div className="max-w-2xl mb-10">
        <p className="landing-sub-title mb-3">Ready to mark?</p>
        <h2 className="landing-title-section mb-5">
          Generate feedback in real time
        </h2>
        <p className="landing-light-text">
          As you mark, Stellar calculates averages and grade bandings in real
          time. It displays summative feedback from your qualification's rubric,
          giving you all the information you need to assess fairly and
          efficiently.
        </p>
      </div>

      <motion.div
        animate={inView ? 'visible' : 'hidden'}
        ref={ref}
        variants={list}
        className="flex gap-6 flex-col lg:flex-row"
      >
        {cards.map(({ description, title, img }) => (
          <motion.div
            variants={item}
            key={title}
            className="flex flex-1 justify-between flex-col h-[326px] bg-purple-50 p-6 rounded-lg"
          >
            <img src={img} alt="brua" className="mx-auto mb-4 h-[150px]" />

            <div className="flex-1">
              <p className="text-xl font-medium mb-2">{title}</p>
              <p className="text-gray-800">{description}</p>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </section>
  )
}

export default ReadyToMark
