import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Group } from '../../types/groups'
import { Project } from '../../types/projects'

type ProjectAccordionProps = {
  projects: Project[]
  groups: Group[]
}

const ProjectAccordion: React.FC<ProjectAccordionProps> = ({
  projects,
  groups,
}) => {
  const [toggle, setToggle] = useState(true)

  return (
    <div className="w-full border-b border-gray-200 py-6">
      <button
        onClick={() => setToggle(old => !old)}
        className={clsx({
          'flex w-full justify-between items-center text-link text-lg font-medium':
            true,
          'text-gray-500': !toggle,
          'text-purle-500': toggle,
        })}
      >
        <p>{projects[0].qualificationName}</p>
        {toggle ? (
          <FontAwesomeIcon
            className="leading-icon w-5 h-5"
            icon={faMinusCircle}
          />
        ) : (
          <FontAwesomeIcon
            className="leading-icon w-5 h-5"
            icon={faPlusCircle}
          />
        )}
      </button>

      <div
        className={clsx({
          'flex flex-col gap-3  transition-all duration-500 -m-1': true,
          'max-h-screen': toggle,
          'max-h-0': !toggle,
        })}
      >
        <div className="grid gap-4 overflow-scroll">
          <div className="grid grid-cols-4 font-semibold px-4 pb-2 pt-8 gap-3 min-w-max m-1">
            <p>Project</p>
            <p>Group</p>
            <p># Of Students</p>
            <p></p>
          </div>

          {projects.map(
            ({
              groupName,
              projectName,
              students,
              id,
              haveStudentsBeenEdited,
            }) => {
              return (
                <div
                  className="grid grid-cols-4 card items-center py-0 gap-3 min-w-max m-1"
                  key={id}
                >
                  <div className="my-5 sm:my-6">{projectName}</div>
                  <div className="my-5 sm:my-6">
                    {`${groupName}${haveStudentsBeenEdited ? ' (Edited)' : ''}`}
                  </div>
                  <div className="my-5 sm:my-6">{students.length}</div>
                  <div className="flex items-center justify-end">
                    <Link
                      to={`/projects/${id}/marks`}
                      className="btn btn-primary mr-3 whitespace-nowrap"
                    >
                      Mark Students
                    </Link>
                    <Link
                      to={`/projects/${id}`}
                      className="btn btn-white whitespace-nowrap"
                    >
                      Edit Project
                    </Link>
                  </div>
                </div>
              )
            }
          )}
        </div>
      </div>
    </div>
  )
}
export default ProjectAccordion
