import React from 'react'
import { Link } from 'react-router-dom'
import { APP_TITLE } from '../utils/config'

type ErrorPageProps = {
  title?: string
  subtitle?: string
  text?: string
}

const ErrorPage: React.FC<ErrorPageProps> = props => {
  const handleReload = () => {
    window.location.reload()
  }

  return (
    <div className="min-h-full pt-16 pb-12 flex flex-col mt-12">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <Link to="/projects" title={APP_TITLE}>
            <img
              className="h-12 w-auto"
              src="/img/logox2.png"
              alt={APP_TITLE}
            />
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            {props.subtitle ? (
              <p className="text-sm font-semibold text-secondary-600 uppercase tracking-wide">
                {props.subtitle}
              </p>
            ) : null}
            {props.title ? (
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                {props.title}
              </h1>
            ) : null}
            {props.text ? (
              <p className="mt-2 text-base text-gray-500">{props.text}</p>
            ) : null}
            <div className="mt-6">
              <button onClick={handleReload} className="btn btn-primary">
                Reload page
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ErrorPage
