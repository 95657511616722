import clsx from 'clsx'
import { FastField, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import BreadCrumbs from '../components/BreadCrumbs'
import ErrorPage from '../components/ErrorPage'
import FormikInput from '../components/FormikInput'
import Modal from '../components/Modal'
import Section from '../components/Sections'
import { useProject } from '../hooks/useProjects'
import projectService from '../services/projectService'
import { GroupTDO, Student } from '../types/groups'
import { queryKeys } from '../utils/config'
import { queryClient } from '../utils/tanstack-react-query'
import Table from '../components/Table'
import { createColumnHelper } from '@tanstack/react-table'

const schema = Yup.object().shape({
  studentNameInput: Yup.string().required('Student Name is required'),
  studentEmailInput: Yup.string(),
})

const invalidateProjectQueries = async () => {
  await queryClient.invalidateQueries({
    queryKey: [queryKeys.projects.projects],
  })
  await queryClient.invalidateQueries({
    queryKey: [queryKeys.projects.project],
  })
}

const ProjectStudents = () => {
  const { id } = useParams<{ id: string }>()
  const { data: project, isLoading, isError } = useProject()
  const [selectedStudent, setSelectedStudent] = useState<Student | undefined>(
    undefined
  )
  const [toggle, setToggle] = useState(false)

  const addStudent = useMutation({
    mutationFn: projectService.addStudent,
    onSuccess: async () => {
      await invalidateProjectQueries()
      toast.success('Student added successfully.')
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  const removeStudent = useMutation({
    mutationFn: projectService.removeStudent,
    onSuccess: async () => {
      await invalidateProjectQueries()
      toast.success('Student removed successfully.')
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  const columnHelper = createColumnHelper<Student>()

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        enableSorting: true,
      }),
      columnHelper.accessor('overall', {
        id: 'grade',
        header: 'Grade',
        enableSorting: true,
        cell: info => {
          return info.getValue() || '-'
        },
      }),
      columnHelper.accessor('group', {
        id: 'group',
        header: 'Group',
        enableSorting: false,
        cell: info => {
          return info.getValue() ? project?.groupName : '-'
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: '',
        enableSorting: false,
        cell: info => {
          const student = info.row.original
          return (
            <div className="flex items-center justify-end gap-3">
              <Link to={student.studentId || ''} className="btn btn-primary">
                Mark Student
              </Link>
              <button
                type="button"
                onClick={() => {
                  setSelectedStudent(student)
                  setToggle(true)
                }}
                className="text-link-danger"
              >
                Remove Student
              </button>
            </div>
          )
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project?.students]
  )

  if (isLoading) return null

  if (isError || !project) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }

  const breadCrumbPages = [
    { name: 'Manage Projects', href: '/projects' },
    { name: project.projectName, href: `/projects/${id}/marks` },
  ]

  const initialValues: Omit<GroupTDO, 'name' | 'students' | 'isArchived'> = {
    studentNameInput: '',
    studentEmailInput: '',
  }

  const handleOnSubmit = async (
    data: Omit<GroupTDO, 'name' | 'students' | 'isArchived'>,
    formikHelpers: FormikHelpers<
      Omit<GroupTDO, 'name' | 'students' | 'isArchived'>
    >
  ) => {
    const student: Student = {
      name: data.studentNameInput,
      email: data.studentEmailInput,
      communicate: project.markingAreas.includes('communicate') ? 0 : null,
      concepts: project.markingAreas.includes('concepts') ? 0 : null,
      failAndFix: project.markingAreas.includes('failAndFix') ? 0 : null,
      reflect: project.markingAreas.includes('reflect') ? 0 : null,
      research: project.markingAreas.includes('research') ? 0 : null,
      feedback: '',
      overall: 0,
    }

    if (id) {
      await addStudent.mutateAsync({ projectId: id, student })

      formikHelpers.resetForm()
    }
  }

  const handleRemoveStudent = async () => {
    if (selectedStudent && id) {
      await removeStudent.mutateAsync({
        projectId: id,
        student: selectedStudent,
      })
      setToggle(false)
    }
  }

  return (
    <div className="container flex flex-col mx-auto items-start pb-12">
      <Modal
        title="Remove Student"
        description={`Are you sure you want to remove ${selectedStudent?.name} from ${project.projectName}?`}
        toggle={toggle}
        setToggle={setToggle}
        afterLeave={() => setSelectedStudent(undefined)}
      >
        <div className="flex gap-3 mt-8">
          <button
            className="btn btn-white w-full text-center block"
            onClick={() => setToggle(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-delete w-full text-center block"
            onClick={handleRemoveStudent}
          >
            Remove
          </button>
        </div>
      </Modal>
      <BreadCrumbs breadCrumbPages={breadCrumbPages} />
      <h1 className="section-title">{project.projectName}</h1>
      <p className="qualification-tag">{project.qualificationName}</p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({
          isSubmitting,
          errors,
        }: FormikProps<Omit<GroupTDO, 'name' | 'students' | 'isArchived'>>) => {
          return (
            <Form className="w-full">
              <Section.Container>
                <Section.Body>
                  <Section.Item
                    htmlFor="name"
                    label="Add Student"
                    description="Manually add a student to the project"
                  >
                    <div className=" flex flex-col lg:flex-row justify-between mb-8 gap-6">
                      <fieldset
                        disabled={isSubmitting}
                        className="flex-1 space-y-3 w-full lg:w-auto"
                      >
                        <FastField
                          type="text"
                          name="studentNameInput"
                          component={FormikInput}
                          label="Student Name"
                          placeholder="Student Name"
                        />
                      </fieldset>
                      <fieldset
                        disabled={isSubmitting}
                        className="flex-1 space-y-3 w-full lg:w-auto"
                      >
                        <FastField
                          type="email"
                          name="studentEmailInput"
                          component={FormikInput}
                          label="Student Email"
                          placeholder="Optional Email"
                        />
                      </fieldset>

                      <div
                        className={clsx({
                          'flex justify-center items-end': true,
                          'items-center mt-1':
                            errors.studentEmailInput || errors.studentNameInput,
                        })}
                      >
                        <button
                          type="submit"
                          className="btn btn-white space-y-5 w-full lg:w-auto block"
                        >
                          Add Student
                        </button>
                      </div>
                    </div>
                  </Section.Item>
                </Section.Body>
              </Section.Container>
            </Form>
          )
        }}
      </Formik>
      <div className="w-full">
        {project.students.length > 0 ? (
          <Table
            columns={columns}
            data={Array.isArray(project.students) ? project.students : []}
          />
        ) : (
          <div className="card text-center">
            <p className="page-title mb-1">No Students Added</p>
            <p className="light-text">To begin, add students individually.</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProjectStudents
