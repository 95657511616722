import clsx from 'clsx'
import { FastField, Form, Formik, FormikProps } from 'formik'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import React, { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import BreadCrumbs from '../components/BreadCrumbs'
import ErrorPage from '../components/ErrorPage'
import FormikTextarea from '../components/FormikTextarea'
import MarkDescription from '../components/Mark/MarkDescription'
import MarkDescriptionPrint from '../components/Mark/MarkDescriptionPrint'
import MarkForm from '../components/Mark/MarkForm'
import Radar from '../components/Radar'
import Spinner from '../components/Spinner'
import { useProject } from '../hooks/useProjects'
import projectService from '../services/projectService'
import qualificationService from '../services/qualificationService'
import { Student } from '../types/groups'
import { StudentMarks } from '../types/projects'
import { markingAreasType, queryKeys } from '../utils/config'
import { calculateAverage } from '../utils/markUtils'
import { queryClient } from '../utils/tanstack-react-query'

const schema = Yup.object().shape({
  research: Yup.number().min(0).max(100).nullable(),
  concepts: Yup.number().min(0).max(100).nullable(),
  failAndFix: Yup.number().min(0).max(100).nullable(),
  communicate: Yup.number().min(0).max(100).nullable(),
  reflect: Yup.number().min(0).max(100).nullable(),
})

const feedbackSchema = Yup.object().shape({
  feedback: Yup.string(),
})

const Mark = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [isDownloading, setIsDownloading] = useState(false)
  const { id, studentId } = useParams<{ id: string; studentId: string }>()
  const [activeMark, setActiveMark] = useState<markingAreasType | null>(null)
  const { data: project, isLoading: isProjectLoading, isError } = useProject()

  const { data: rubrics, isLoading: isQualificationLoading } = useQuery({
    queryKey: [queryKeys.qualifications.rubric, studentId],
    queryFn: () => qualificationService.getRubrics(project?.qualification),
    enabled: !!project?.qualification,
    // This needs to be here, if not clicking next student wont work after loop
    gcTime: 0,
  })

  const updateStudent = useMutation({
    mutationFn: projectService.updateStudent,
    onSuccess: async () => {
      toast.success('Mark Updated!')
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.projects.project],
      })
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  const updateStudentFeedback = useMutation({
    mutationFn: projectService.updateStudentFeedback,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.projects.project],
      })
      toast.success('Feedback Updated!')
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  if (isProjectLoading || isQualificationLoading) {
    return null
  }

  if (isError || !project || !studentId) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }

  const student = project.students.find(
    ({ studentId: id1 }) => id1 === studentId
  )
  const currentStudentIndex = project.students.findIndex(
    x => x.studentId === studentId
  )
  const nextStudent = () => {
    if (project.students[currentStudentIndex + 1]) {
      return `/projects/${id}/marks/${
        project.students[currentStudentIndex + 1].studentId
      }`
    }

    return `/projects/${id}/marks/${project.students[0].studentId}`
  }

  if (!student || !rubrics) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }

  const breadCrumbPages = [
    { name: 'Manage Projects', href: '/projects' },
    { name: project.projectName, href: `/projects/${id}/marks` },
    { name: student.name, href: `/projects/${id}/marks/${studentId}` },
  ]

  const markInitialValues: Omit<StudentMarks, 'overall'> = {
    research: project.markingAreas.includes('research')
      ? student.research || 0
      : null,
    concepts: project.markingAreas.includes('concepts')
      ? student.concepts || 0
      : null,
    failAndFix: project.markingAreas.includes('failAndFix')
      ? student.failAndFix || 0
      : null,
    communicate: project.markingAreas.includes('communicate')
      ? student.communicate || 0
      : null,
    reflect: project.markingAreas.includes('reflect')
      ? student.reflect || 0
      : null,
  }

  const feedbackInitialValues: Pick<Student, 'feedback'> = {
    feedback: student.feedback || '',
  }

  const handleOnSubmit = async (marks: Omit<StudentMarks, 'overall'>) => {
    if (id && studentId) {
      const overall = calculateAverage(project.markingAreas, marks)

      await updateStudent.mutateAsync({
        projectId: id,
        studentId,
        marks: { ...marks, overall },
      })
    }
  }

  const handleOnSubmitFeedback = async (data: Pick<Student, 'feedback'>) => {
    if (id && studentId && data.feedback) {
      await updateStudentFeedback.mutateAsync({
        projectId: id,
        studentId,
        feedback: data.feedback,
      })
    }
  }

  const data = [
    {
      name: 'Research',
      mark: student.research === null ? 0 : student.research,
    },
    {
      name: 'Concepts',
      mark: student.concepts === null ? 0 : student.concepts,
    },
    {
      name: 'Fail & Fix',
      mark: student.failAndFix === null ? 0 : student.failAndFix,
    },
    {
      name: 'Communicate',
      mark: student.communicate === null ? 0 : student.communicate,
    },
    {
      name: 'Reflect',
      mark: student.reflect === null ? 0 : student.reflect,
    },
  ]

  const handleDownload = () => {
    setIsDownloading(true)

    setTimeout(async () => {
      try {
        const pdf = new jsPDF({})
        if (!ref.current) return null
        const canvas = await html2canvas(ref.current, {
          backgroundColor: 'true',
          windowWidth: 1512,
        })
        const imgData = canvas.toDataURL('image/png')
        if (!imgData) return null
        pdf.addImage(imgData, 'JPEG', 15, 15, 180, 0, undefined, 'SLOW')
        // pdf.output('dataurlnewwindow')
        pdf.save(`${student.name}-${project.projectName}`)
      } catch (error) {
        console.log(
          '🚀 ~ file: Mark.tsx ~ line 167 ~ handleDownload ~ error',
          error
        )
      } finally {
        setIsDownloading(false)
      }
    }, 1000)
  }

  return (
    <div className="container flex flex-col mx-auto items-start pb-16">
      <BreadCrumbs breadCrumbPages={breadCrumbPages} />
      <div className="w-full flex justify-end gap-6">
        <button className="btn btn-white print:hidden" onClick={handleDownload}>
          {isDownloading && <Spinner className="h-4 w-4 trailing-icon" />}{' '}
          Download PDF
        </button>
        <Link to={nextStudent()} className="btn btn-primary print:hidden">
          Mark Next Student
        </Link>
      </div>
      <div>
        <h1 className="section-title">{student.name}</h1>
        <div className="flex gap-3 mb-6">
          <p className="qualification-tag">{project.projectName}</p>
          <p className="qualification-tag h-6">{project.qualificationName}</p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full print:grid-cols-1 lg:print:grid-cols-1">
          <Formik
            enableReinitialize
            initialValues={markInitialValues}
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting, setFieldValue, values, submitForm, dirty }) => {
              return (
                <Form className="card flex justify-between gap-1 md:gap-3 xl:gap-12 px-2 sm:px-6 w-[calc(100% - 10px)] print:hidden">
                  <MarkForm
                    isSubmitting={isSubmitting}
                    setFieldValue={setFieldValue}
                    values={values}
                    submitForm={submitForm}
                    rubrics={rubrics}
                    setActiveMark={setActiveMark}
                    isDirty={dirty}
                  />
                </Form>
              )
            }}
          </Formik>

          <div className="hidden sm:block print:text-center print:mx-auto">
            <Radar data={data} key="main" />
          </div>
        </div>

        <ul className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2 items-start">
          {activeMark !== null ? (
            <div className="hidden sm:block print:hidden sm:print:hidden">
              <MarkDescription
                rubrics={rubrics}
                mark={activeMark}
                value={student[activeMark]}
                active
              />
            </div>
          ) : (
            <div className="hidden sm:block print:hidden sm:print:hidden">
              <li className="card min-h-[184px] print:border-0 print:shadow-none print:bg-transparent">
                <p className="font-medium print:text-lg mb-4">
                  Select a Category to Mark
                </p>
                <p className="text-gray-500 font-normal print:text-lg">
                  Dragging the slider or entering the grade will display a
                  preview of the mark rationale.
                </p>
              </li>
            </div>
          )}
          <MarkDescription
            rubrics={rubrics}
            mark={markingAreasType.overall}
            value={student.overall}
          />

          <h3 className="text-2xl font-semibold my-6">Mark Description</h3>

          <div />
          <MarkDescription
            rubrics={rubrics}
            mark={markingAreasType.research}
            value={student.research}
          />
          <MarkDescription
            rubrics={rubrics}
            mark={markingAreasType.concepts}
            value={student.concepts}
          />
          <MarkDescription
            rubrics={rubrics}
            mark={markingAreasType.failAndFix}
            value={student.failAndFix}
          />
          <MarkDescription
            rubrics={rubrics}
            mark={markingAreasType.communicate}
            value={student.communicate}
          />
          <MarkDescription
            rubrics={rubrics}
            mark={markingAreasType.reflect}
            value={student.reflect}
          />
          {project.feedback ? (
            <Formik
              enableReinitialize
              initialValues={feedbackInitialValues}
              validationSchema={feedbackSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleOnSubmitFeedback}
            >
              {({
                isSubmitting,
                dirty,
              }: FormikProps<Pick<Student, 'feedback'>>) => {
                return (
                  <Form className="card print:border-0 print:shadow-none print:bg-transparent">
                    <p className="font-medium print:text-lg mb-4 print:hidden">
                      Additional Feedback
                    </p>
                    <p className="text-gray-500 font-normal print:text-lg mb-4 print:hidden">
                      Add customised feedback for this student here.
                    </p>

                    <fieldset disabled={isSubmitting}>
                      <FastField
                        type="text"
                        name="feedback"
                        component={FormikTextarea}
                        label="Feedback"
                        placeholder="Your feedback here"
                      />
                    </fieldset>
                    {dirty && (
                      <button
                        className="btn btn-primary mt-3 float-right right print:hidden"
                        disabled={isSubmitting}
                      >
                        {project.feedback ? 'Update Feedback' : 'Save Feedback'}
                      </button>
                    )}
                  </Form>
                )
              }}
            </Formik>
          ) : null}
        </ul>
      </div>
      <div className="self-end bg-purple-700 p-4 flex flex-row justify-start items-center rounded-lg mt-12">
        <p className="text-white m-0">Finished Marking?</p>
        <Link to={`/projects/${id}/marks`} className="btn btn-white ml-6">
          Return to Student List
        </Link>
      </div>
      <div
        ref={ref}
        className={clsx({
          'hidden ': !isDownloading,
          'block pb-6': isDownloading,
        })}
      >
        <p className="section-title text-4xl">{student.name}</p>
        <p className="mb-1">
          {project.projectName}, {project.qualificationName}
        </p>
        <p className="light-text">
          {student.research !== null && `Research ${student.research}. `}
          {student.concepts !== null && `Concepts ${student.concepts}. `}
          {student.failAndFix !== null && `Fail & Fix ${student.failAndFix}. `}
          {student.communicate !== null &&
            `Communicate ${student.communicate}. `}
          {student.reflect !== null && `Reflect ${student.reflect}. `}
        </p>

        <div className="mt-6 grid grid-cols-2 items-center">
          <div>
            <MarkDescriptionPrint
              rubrics={rubrics}
              mark={markingAreasType.overall}
              value={student.overall}
            />
          </div>

          <div className="w-[400px] h-[400px] place-self-center">
            <Radar data={data} key="main" />
          </div>
        </div>

        <p className="text-2xl font-semibold mb-20">Marks</p>

        <div className="grid grid-cols-2 gap-x-6 gap-y-12">
          <MarkDescriptionPrint
            rubrics={rubrics}
            mark={markingAreasType.research}
            value={student.research}
          />
          <MarkDescriptionPrint
            rubrics={rubrics}
            mark={markingAreasType.concepts}
            value={student.concepts}
          />
          <MarkDescriptionPrint
            rubrics={rubrics}
            mark={markingAreasType.failAndFix}
            value={student.failAndFix}
          />
          <MarkDescriptionPrint
            rubrics={rubrics}
            mark={markingAreasType.communicate}
            value={student.communicate}
          />
          <MarkDescriptionPrint
            rubrics={rubrics}
            mark={markingAreasType.reflect}
            value={student.reflect}
          />
        </div>

        {student.feedback && (
          <div className="max-w-xl">
            <p className="text-md font-bold ml-1">Feedback</p>
            <p className="font-medium text-gray-500">{student.feedback}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Mark
