import { useQuery } from '@tanstack/react-query'
import { Navigate, useLocation } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import authService from '../../services/authService'
import subscriptionService from '../../services/subscriptionService'
import { queryKeys } from '../../utils/config'
import LoadingScreen from '../LoadingScreen'

type AuthGuardProps = {
  children?: React.ReactNode
}

const AuthGuard: React.FC = ({ children }: AuthGuardProps) => {
  const location = useLocation()
  const user = useUser()

  const { data: userSubscription, isLoading } = useQuery({
    queryKey: [queryKeys.stripe.mySubscription],
    queryFn: () => subscriptionService.getFirebaseSubscription(user.data?.id),
    enabled: !!user.data,
  })

  if (isLoading || user.isLoading) return <LoadingScreen />

  if (!user.data) return <Navigate to="/auth/sign-in" />

  if (!userSubscription?.subscription) {
    if (user.data.organisation) {
      // *Should be impossible to dont have a subscription being a organisation
      // *To cover this case the user wont have access to the app and will be redirect to the homepage
      authService.logout()
      return <Navigate replace to="/" />
    }
    return <Navigate replace to="/subscribe" />
  }

  if (
    userSubscription.subscription < Math.round(new Date().getTime() / 1000) &&
    location.pathname !== '/account'
  ) {
    return <Navigate replace to="/account" />
  }

  return <>{children}</>
}

export default AuthGuard
