import React from 'react'
import { Outlet, RouteObject, useRoutes } from 'react-router-dom'
import AdminGuard from './components/auth/AdminGuard'
import AuthGuard from './components/auth/AuthGuard'
import GuestGuard from './components/auth/GuestGuard'
import SubGuard from './components/auth/SubGuard'
import FrontPageLayout from './components/layouts/FrontPageLayout'
import HomeLayout from './components/layouts/HomeLayout'
import SplitAuthLayout from './components/layouts/SplitAuthLayout'
import Account from './pages/Account'
import AddOrganisationUser from './pages/AddOrganisationUser'
import FrontPage from './pages/FrontPage'
import Groups from './pages/Groups'
import Mark from './pages/Mark'
import NewGroup from './pages/NewGroup'
import NewProject from './pages/NewProject'
import NotFound from './pages/NotFound'
import Privacy from './pages/Privacy'
import Projects from './pages/Projects'
import ProjectStudents from './pages/ProjectStudents'
import RecoverPassword from './pages/RecoverPassword'
import Register from './pages/Register'
import SignIn from './pages/SignIn'
import Subscribe from './pages/Subscribe'
import Terms from './pages/Terms'
import { GroupMarks } from './pages/GroupMarks'

interface RouteWrapperProps {
  guard?: React.FC
  layout?: React.FC
}

const RouteWrapper = ({ guard, layout }: RouteWrapperProps) => {
  const Guard = guard ?? React.Fragment
  const Layout = layout ?? React.Fragment
  return (
    <Guard>
      <Layout>
        <Outlet />
      </Layout>
    </Guard>
  )
}

const routesConfig: RouteObject[] = [
  {
    path: '/',
    element: <RouteWrapper layout={FrontPageLayout} />,
    children: [
      {
        index: true,
        element: <FrontPage />,
      },
      {
        path: '/terms-and-conditions',
        element: <Terms />,
      },
      {
        path: '/privacy-policy',
        element: <Privacy />,
      },
    ],
  },
  {
    path: '/admin',
    element: <RouteWrapper guard={AdminGuard} layout={HomeLayout} />,
    children: [
      {
        path: '/admin/add-user',
        element: <AddOrganisationUser />,
      },
    ],
  },
  {
    path: '/',
    element: <RouteWrapper guard={AuthGuard} layout={HomeLayout} />,
    children: [
      {
        path: '/projects',
        element: <Projects />,
      },
      {
        path: '/new-project',
        element: <NewProject />,
      },
      {
        path: '/projects/:id',
        element: <NewProject />,
      },
      {
        path: '/projects/:id/marks',
        element: <ProjectStudents />,
      },
      {
        path: '/projects/:id/marks/:studentId',
        element: <Mark />,
      },
      {
        path: '/groups',
        element: <Groups />,
      },
      {
        path: '/groups/:id',
        element: <NewGroup />,
      },
      {
        path: '/groups/:id/marks',
        element: <GroupMarks />,
      },
      {
        path: '/new-group',
        element: <NewGroup />,
      },
      {
        path: '/account',
        element: <Account />,
      },
    ],
  },
  {
    path: '/auth',
    element: <RouteWrapper guard={GuestGuard} layout={SplitAuthLayout} />,
    children: [
      {
        path: '/auth/sign-in',
        element: <SignIn />,
      },
      {
        path: '/auth/register',
        element: <Register />,
      },
      {
        path: '/auth/recovery',
        element: <RecoverPassword />,
      },
      {
        path: '/auth/subscribe',
        element: <Subscribe />,
      },
    ],
  },
  {
    path: '/subscribe',
    element: <RouteWrapper guard={SubGuard} />,
    children: [
      {
        index: true,
        element: <Subscribe />,
      },
      {
        path: '/subscribe/:sessionId',
        element: <Subscribe />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

const Routes = () => {
  const routing = useRoutes(routesConfig)

  return routing
}

export default Routes
