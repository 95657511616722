import { faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FastField } from 'formik'
import Papa from 'papaparse'
import React, { useState } from 'react'
import { GroupTDO, StudentBasic } from '../../types/groups'
import DropZone from '../DropZone'
import FormikInput from '../FormikInput'
import Modal from '../Modal'
import Section from '../Sections'
import FormikCheckbox from '../FormikCheckbox'
import { createColumnHelper } from '@tanstack/react-table'
import Table from '../Table'
import { useParams } from 'react-router-dom'

type NewGroupFormProps = {
  isSubmitting: boolean
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  values: GroupTDO
}

const NewGroupForm: React.FC<NewGroupFormProps> = ({
  isSubmitting,
  values,
  setFieldValue,
}) => {
  const { id } = useParams<{ id: string }>()
  const [csvToggle, setCsvToggle] = useState(false)
  const [deleteStudentToggle, setDeleteStudentToggle] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null)
  const [csv, setCsv] = useState<StudentBasic[]>([])

  const columnHelper = createColumnHelper<StudentBasic>()

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        enableSorting: true,
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: 'Email',
        enableSorting: true,
      }),
      columnHelper.display({
        id: 'actions',
        header: '',
        enableSorting: false,
        cell: info => {
          return (
            <button
              type="button"
              onClick={() => {
                setDeleteStudentToggle(true)
                setDeleteIndex(info.row.index)
              }}
              className="text-link-danger block ml-auto"
            >
              Delete
            </button>
          )
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.students]
  )

  const handleRemoveStudent = () => {
    const newArr = values.students.filter((_, i) => i !== deleteIndex)
    setFieldValue('students', newArr)
    setDeleteStudentToggle(false)
  }

  const handleAddStudent = () => {
    if (!values.studentNameInput) {
      return
    }
    setFieldValue('students', [
      ...values.students,
      {
        name: values.studentNameInput,
        email: values.studentEmailInput,
      },
    ])

    setFieldValue('studentNameInput', '')
    setFieldValue('studentEmailInput', '')
  }

  const AddCSVStudentsToTable = (data: string[][]) => {
    const filter = data.filter(function (item) {
      return item && item.length > 1 && item[0]
    })

    const parsedStudents = filter.map((d: string[]) => {
      return {
        name: d[0],
        email: d[1],
      }
    })

    return parsedStudents
  }

  return (
    <>
      <Modal
        title="Remove Student"
        description="Are you sure you want to remove this student?"
        toggle={deleteStudentToggle}
        setToggle={setDeleteStudentToggle}
        afterLeave={() => setDeleteIndex(null)}
      >
        <div className="flex gap-3 mt-8">
          <button
            className="btn btn-white w-full text-center block"
            onClick={() => setDeleteStudentToggle(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-delete w-full text-center block"
            onClick={handleRemoveStudent}
          >
            Remove
          </button>
        </div>
      </Modal>
      <Modal
        title="Group Add by CSV"
        description="Using a CSV you can bulk add students to a Group. Your CSV should use the following structure:"
        toggle={csvToggle}
        setToggle={setCsvToggle}
      >
        <div>
          <p className="text-sm font-medium text-gray-800 mb-8">
            Student Name, Email{' '}
            <span className="text-sm font-normal text-gray-500 ">
              (Optional)
            </span>
          </p>

          <DropZone
            onChange={file => {
              if (file && file.length > 0) {
                Papa.parse<string[]>(file[0], {
                  complete: results => {
                    if (results) {
                      const groupBunch = AddCSVStudentsToTable(results.data)
                      setCsv(groupBunch)
                    }
                  },
                })
              }
            }}
          />

          <div className="flex gap-3 mt-8">
            <button
              className="btn btn-white w-full block"
              type="button"
              onClick={() => setCsvToggle(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary w-full block"
              type="button"
              onClick={() => {
                setFieldValue('students', [...csv, ...values.students])
                setCsvToggle(false)
              }}
            >
              Add
            </button>
          </div>
        </div>
      </Modal>

      <Section.Body>
        <Section.Item
          htmlFor="name"
          label="Group Details"
          description="Enter Group Name"
        >
          <fieldset disabled={isSubmitting}>
            <div className="space-y-3">
              <FastField
                type="text"
                name="name"
                component={FormikInput}
                label="Group Name"
                placeholder="My Great Group"
                autoFocus
              />
            </div>
          </fieldset>
          {/** if there's no id in the url, we are creating a new group */}
          {id && (
            <fieldset
              className="space-y-3 mt-7 w-fit"
              disabled={isSubmitting || !Boolean(id)}
            >
              <FastField
                name="isArchived"
                component={FormikCheckbox}
                label="Archive Group"
                description="Check this box to hide the group from the active groups list."
                checked={values.isArchived}
              />
            </fieldset>
          )}
        </Section.Item>

        <Section.Item
          htmlFor="studentNameInput"
          label="Students"
          description="Add and remove students belonging to this Group."
        >
          <div className=" flex flex-col lg:flex-row justify-between mb-8">
            <div className="flex gap-6 w-full lg:w-auto">
              <fieldset
                disabled={isSubmitting}
                className="space-y-3 flex-1 lg:flex-auto w-full md:w-[200px]"
              >
                <FastField
                  type="text"
                  name="studentNameInput"
                  component={FormikInput}
                  label="Student Name"
                  placeholder="Student Name"
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      handleAddStudent()
                    }
                  }}
                />
              </fieldset>
              <fieldset
                disabled={isSubmitting}
                className="space-y-3 flex-1 lg:flex-auto w-full md:w-[200px]"
              >
                <FastField
                  type="email"
                  name="studentEmailInput"
                  component={FormikInput}
                  label="Student Email"
                  placeholder="Optional Email"
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      handleAddStudent()
                    }
                  }}
                />
              </fieldset>
            </div>

            <div className="flex gap-6 self-end mt-4 lg:mt-0 w-full lg:pl-6 lg:justify-between">
              <button
                type="button"
                className="btn btn-primary btn-full lg:w-auto"
                onClick={handleAddStudent}
              >
                Add Student
              </button>
              <button
                type="button"
                className="btn btn-white btn-full lg:w-auto"
                onClick={() => setCsvToggle(true)}
              >
                <FontAwesomeIcon className="leading-icon" icon={faFilePdf} />
                Group Add
              </button>
            </div>
          </div>

          {values.students.length > 0 ? (
            <Table columns={columns} data={values.students} />
          ) : (
            <div className="card text-center">
              <p className="page-title mb-1">No Students Added</p>
              <p className="light-text">
                To begin, either group add students via CSV, or add students
                individually
              </p>
            </div>
          )}
        </Section.Item>
      </Section.Body>
    </>
  )
}
export default NewGroupForm
