import * as React from 'react'

const Concepts = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={32} height={32} rx={16} fill="#fef08a" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.512 14.027c0-2.921 2.316-5.527 5.528-5.527a5.528 5.528 0 0 1 4.16 9.164c-.523.596-1.343 1.846-1.647 2.894v.002h-1.507v-.003c0-.152.024-.304.07-.45a9.927 9.927 0 0 1 1.95-3.437c.64-.731.994-1.67.994-2.643a4.025 4.025 0 0 0-4.02-4.02c-2.144 0-4.025 1.708-4.018 4.02.002.974.347 1.907.992 2.643a9.905 9.905 0 0 1 1.953 3.444c.045.144.067.292.067.442v.004h-1.507v-.002c-.304-1.048-1.124-2.298-1.646-2.894a5.497 5.497 0 0 1-1.369-3.637Zm5.528-3.014a3.018 3.018 0 0 0-3.015 3.014.502.502 0 1 0 1.005 0c0-1.108.902-2.01 2.01-2.01a.502.502 0 1 0 0-1.005ZM13.53 22.92c0 .099.029.195.084.277l.77 1.157c.092.14.25.225.418.225h2.476a.502.502 0 0 0 .418-.225l.77-1.157a.506.506 0 0 0 .084-.277l.002-1.356h-5.024l.001 1.356Z"
      fill="#a16207"
    />
  </svg>
)

export default Concepts
