import { AnimatePresence, motion } from 'framer-motion'
import { wrap } from 'popmotion'
import { useRef, useState } from 'react'

const images = [
  '/img/features/01_Stellar-Features.jpeg',
  '/img/features/02_Stellar-Features.jpeg',
  '/img/features/03_Stellar-Features.jpeg',
]

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}

const Features = () => {
  const [[page, direction], setPage] = useState([0, 0])
  const ref = useRef<HTMLDivElement>(null)

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page)

  const paginate = (newDirection: number) => {
    setPage([newDirection, newDirection])
  }

  const height = ref.current?.clientHeight ? ref.current?.clientHeight / 3 : 0

  const barVariants = {
    enter: {
      y: 0,
    },
    center: (imageIndex: number) => {
      switch (imageIndex) {
        case 0:
          return { y: 0 }
        case 1:
          return { y: height * 1 }
        case 2:
          return { y: height * 2 }

        default:
          return { y: 0 }
      }
    },
  }

  return (
    <section className="mb-44" id="features">
      <div className="max-w-2xl mb-10">
        <p className="landing-sub-title mb-3">Features</p>
        <h2 className="landing-title-section mb-5">
          Created for robust and transparent assessment
        </h2>
        <p className="landing-light-text">
          On average, educators using Stellar reduce their marking time by half.
        </p>
      </div>

      <div className="flex relative">
        <img
          src="/img/kids.png"
          alt="class"
          className="hidden md:block absolute z-20 h-[300px] -right-24 -bottom-32"
        />
        <div className="flex flex-row flex-1">
          <div className="hidden md:block w-2 h-auto bg-gray-100" ref={ref}>
            <motion.div
              initial="enter"
              animate="center"
              variants={barVariants}
              custom={imageIndex}
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
              }}
              className="w-full h-1/3 bg-purple-600"
            />
          </div>

          <div>
            <button
              onClick={() => paginate(0)}
              className="md:px-8 py-8 text-left outline-none block"
            >
              <p className="text-xl font-medium mb-2">
                Mark multiple projects at the same time
              </p>
              <p className="text-gray-500">
                With Stellar you can assess an unlimited amount of projects and
                students from one dashboard with ease.
              </p>
            </button>

            <button
              onClick={() => paginate(1)}
              className="md:px-8 py-8 text-left outline-none block"
            >
              <p className="text-xl font-medium mb-2">
                See your marking evolve in real time
              </p>
              <p className="text-gray-500">
                Using our interactive assessment tool and detailed qualification
                rubric you can adjust the mark to perfectly match each student's
                progress.
              </p>
            </button>

            <button
              onClick={() => paginate(2)}
              className="md:px-8 py-8 text-left outline-none block"
            >
              <p className="text-xl font-medium mb-2">
                Export assessment results as a PDF
              </p>
              <p className="text-gray-500">
                Easily share the results with each student by providing them
                with personalised feedback which maps against the five learning
                outcomes.
              </p>
            </button>
          </div>
        </div>

        <div className="hidden md:flex relative justify-center items-center lg:items-start z-10 w-full overflow-hidden flex-1 rounded-xl">
          <AnimatePresence initial={false} custom={direction}>
            <motion.img
              key={page}
              src={images[imageIndex]}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x)

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1)
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1)
                }
              }}
              className="absolute lg:h-full object-cover rounded-xl"
            />
          </AnimatePresence>
        </div>
      </div>
    </section>
  )
}

export default Features
