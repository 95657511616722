import { FieldProps, getIn } from 'formik'
import React from 'react'
import { displayIcon } from '../../utils/markUtils'

const FormikMarkCheckbox: React.ComponentType<
  FieldProps & { label: string }
> = ({ field, form, label, ...rest }) => {
  const error = getIn(form.errors, field.name)
  const isTouched = getIn(form.touched, field.name)
  const hasError = isTouched && error

  return (
    <div className="flex">
      <div className="flex flex-col items-center">
        {displayIcon({ check: field.name, active: field.value, size: '2x' })}

        <div className="flex items-center gap-3">
          <div className="ml-auto flex items-center h-5">
            <input
              id={field.name}
              style={{ boxShadow: 'none' }}
              className="form-checkbox h-4 w-4 text-secondary-700 border-gray-300 rounded cursor-pointer focus:ring-0"
              type="checkbox"
              {...field}
              {...rest}
            />
          </div>
          <label htmlFor={field.name}>
            <p className="text-md font-medium text-gray-700 cursor-pointer">
              {label}
            </p>
          </label>
        </div>
      </div>
      {hasError ? (
        <p className="mt-1 text-xs text-red-900" id={`${field.name}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  )
}

export default FormikMarkCheckbox
