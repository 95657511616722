import { Field } from 'formik'
import { StudentMarks } from '../../types/projects'
import { Rubric } from '../../types/qualifications'
import { markingAreasType } from '../../utils/config'
import { displayEvaluation } from '../../utils/markUtils'
import FormikRange from '../FormikRange'
import { useEffect } from 'react'

type MarkFormProps = {
  isSubmitting: boolean
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  values: Omit<StudentMarks, 'overall'>
  submitForm: (() => Promise<void>) & (() => Promise<any>)
  rubrics: Rubric[]
  setActiveMark: React.Dispatch<React.SetStateAction<markingAreasType | null>>
  isDirty: boolean
}

const MarkForm: React.FC<MarkFormProps> = ({
  isSubmitting,
  values,
  setFieldValue,
  submitForm,
  rubrics,
  setActiveMark,
  isDirty,
}) => {
  useEffect(() => {
    if (!isDirty) {
      return
    }

    ;(async () => {
      await submitForm()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <>
      <Field
        name="research"
        component={FormikRange}
        label="Research"
        onFinalChange={async (value: number[]) => {
          setFieldValue('research', value[0])
          setTimeout(() => {
            setActiveMark(markingAreasType.research)
          }, 200)
        }}
        values={values.research}
        evaluation={
          displayEvaluation(
            rubrics,
            markingAreasType.research,
            values?.research
          ).evaluation
        }
        disabled={isSubmitting}
      />

      <Field
        name="concepts"
        component={FormikRange}
        label="Concepts"
        onFinalChange={async (value: number[]) => {
          setFieldValue('concepts', value[0])
          setTimeout(() => {
            setActiveMark(markingAreasType.concepts)
          }, 200)
        }}
        values={values.concepts}
        evaluation={
          displayEvaluation(
            rubrics,
            markingAreasType.concepts,
            values?.concepts
          ).evaluation
        }
        disabled={isSubmitting}
      />
      <Field
        name="failAndFix"
        component={FormikRange}
        label="Fail & Fix"
        onFinalChange={async (value: number[]) => {
          setFieldValue('failAndFix', value[0])
          setTimeout(() => {
            setActiveMark(markingAreasType.failAndFix)
          }, 200)
        }}
        values={values.failAndFix}
        evaluation={
          displayEvaluation(
            rubrics,
            markingAreasType.failAndFix,
            values?.failAndFix
          ).evaluation
        }
        disabled={isSubmitting}
      />
      <Field
        name="communicate"
        component={FormikRange}
        label="Communicate"
        onFinalChange={async (value: number[]) => {
          setFieldValue('communicate', value[0])
          setTimeout(() => {
            setActiveMark(markingAreasType.communicate)
          }, 200)
        }}
        values={values.communicate}
        evaluation={
          displayEvaluation(
            rubrics,
            markingAreasType.communicate,
            values?.communicate
          ).evaluation
        }
        disabled={isSubmitting}
      />
      <Field
        name="reflect"
        component={FormikRange}
        label="Reflect"
        onFinalChange={async (value: number[]) => {
          setFieldValue('reflect', value[0])
          setTimeout(() => {
            setActiveMark(markingAreasType.reflect)
          }, 200)
        }}
        values={values.reflect}
        evaluation={
          displayEvaluation(rubrics, markingAreasType.reflect, values?.reflect)
            .evaluation
        }
        disabled={isSubmitting}
      />
    </>
  )
}
export default MarkForm
