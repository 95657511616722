import React from 'react'
import { useQuery } from '@tanstack/react-query'
import frontPageService from '../../services/frontPageService'
import { queryKeys } from '../../utils/config'
import Accordion from '../Accordion'
import ErrorPage from '../ErrorPage'

const Support = () => {
  const { data, isError } = useQuery({
    queryKey: [queryKeys.frontPage.faqs],
    queryFn: frontPageService.getFaqs,
  })

  if (isError) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }

  return (
    <section className="flex flex-col md:flex-row lg:gap-14 mb-44" id="faqs">
      <div className="max-w-2xl mb-10">
        <p className="landing-sub-title mb-3">Support</p>
        <h2 className="landing-title-section mb-5">FAQs</h2>
        <p className="landing-light-text">
          Everything you need to know about the product and billing. Can’t find
          the answer you’re looking for? Please chat to our team.
        </p>
      </div>
      <div className="flex flex-col w-full gap-4">
        {data &&
          data.length > 0 &&
          data.map(({ answer, id, question }) => (
            <Accordion key={id} question={question} answer={answer} />
          ))}
      </div>
    </section>
  )
}

export default Support
