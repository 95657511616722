import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from 'firebase/auth'
import {
  CollectionReference,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { RecoverPasswordTDO, RegisterTDO, SignInTDO } from '../types/auth-in'
import { User, UserIdTokenResult } from '../types/user'
import { userRoles } from '../utils/config'
import { auth, functions } from '../utils/firebase'
import { queryClient } from '../utils/tanstack-react-query'
import { usersCollection } from './firebase/collections'

class AuthService {
  getUser = async (id?: string): Promise<User> => {
    let userId = id
    if (!id) {
      userId = await auth.currentUser?.uid
    }

    if (!userId) {
      throw Error('User not logged in')
    }

    const projectDoc = await getDoc(doc(usersCollection, userId))
    const user = projectDoc.data()

    if (!user) {
      throw Error('User not logged in')
    }

    return {
      ...user,
      id: projectDoc.id,
      role: user.role || userRoles.endUser,
      organisation: user.organisation || null,
    }
  }

  logout = async () => {
    await signOut(auth)
    queryClient.clear()
    await queryClient.resetQueries()
  }

  getAccessToken = async (): Promise<string | undefined> =>
    await auth.currentUser?.getIdToken(true)

  getAccessTokenResults = async (): Promise<UserIdTokenResult | undefined> => {
    const result = await auth.currentUser?.getIdTokenResult()

    return result as UserIdTokenResult | undefined
  }

  signInWithEmailAndPassword = async ({
    email,
    password,
  }: SignInTDO): Promise<User> => {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    )
    return await this.getUser(userCredential.user?.uid)
  }

  createUserWithEmailAndPassword = async ({
    email,
    password,
    firstName,
    lastName,
  }: RegisterTDO): Promise<User> => {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )

    await setDoc(
      doc(
        usersCollection as CollectionReference<
          Omit<User, 'role' | 'organisation'>
        >,
        userCredential.user.uid
      ),
      {
        firstName,
        lastName,
        email,
      }
    )

    return await this.getUser(userCredential.user.uid)
  }

  sendPasswordResetEmail = async ({
    email,
  }: RecoverPasswordTDO): Promise<void> => {
    return await sendPasswordResetEmail(auth, email)
  }

  updatePassword = async (password: string) => {
    const user = await auth.currentUser
    if (user) {
      return await updatePassword(user, password)
    }
  }

  updateEmail = async ({ email }: { email: string }) => {
    const user = await auth.currentUser
    if (user) {
      return await updateEmail(user, email)
    }
  }

  updateUser = async ({
    firstName,
    lastName,
    email,
  }: {
    firstName: string
    lastName: string
    email: string
  }) => {
    const user = await auth.currentUser
    if (user) {
      if (user.email !== email) {
        await this.updateEmail({ email })
      }
      await updateDoc(doc(usersCollection, user.uid), {
        firstName,
        lastName,
        email,
      })
    }
  }

  deleteUser = async () => {
    const deleteUser = httpsCallable(functions, 'deleteUserV2')
    return await deleteUser()
  }

  refreshSession = async (password: string) => {
    const user = await auth.currentUser
    if (user && user.email) {
      const credential = EmailAuthProvider.credential(user.email, password)

      await reauthenticateWithCredential(user, credential)
    }
  }

  requestMyData = async () => {
    const dataRequest = httpsCallable(functions, 'dataRequestV2')
    return await dataRequest()
  }
}

const authService = new AuthService()

export default authService
