import { doc, getDoc } from 'firebase/firestore'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { CustomerCards, Product } from '../types/stripe-types'
import { functions } from '../utils/firebase'
import { subscriptionCollection } from './firebase/collections'

class SubscriptionService {
  getFirebaseSubscription = async (id?: string) => {
    const response = await getDoc(doc(subscriptionCollection, id))
    return response.data()
  }

  createStripeSession = async () => {
    const createStripeSession = httpsCallable(
      functions,
      'createStripeSessionV2'
    )
    return await createStripeSession()
  }

  createStripeSetup = async () => {
    const createStripeSetup = httpsCallable(functions, 'createStripeSetupV2')
    return await createStripeSetup()
  }

  getStripeSession = async (sessionId?: string) => {
    const getStripeSession = httpsCallable(functions, 'getStripeSessionV2')
    return await getStripeSession({ sessionId })
  }

  getProduct = async (): Promise<HttpsCallableResult<Product>> => {
    const getProduct = httpsCallable(functions, 'getStripeProductV2')
    const response = (await getProduct()) as any
    return response
  }

  cancelSubscription = async ({
    cancelAtPeriodEnd,
  }: {
    cancelAtPeriodEnd: boolean
  }) => {
    const cancelSubscription = httpsCallable(
      functions,
      'cancelStripeSubscriptionV2'
    )
    return await cancelSubscription({ cancelAtPeriodEnd })
  }

  getPaymentMethods = async (): Promise<
    HttpsCallableResult<CustomerCards[]>
  > => {
    const getPaymentMethods = httpsCallable(
      functions,
      'getStripeCustomerPaymentMethodsV2'
    )
    return (await getPaymentMethods()) as any
  }

  getStripeCustomer = async () => {
    const getStripeCustomer = httpsCallable(functions, 'getStripeCustomerV2')
    return await getStripeCustomer()
  }

  setStripeDefaultPayment = async (cardId: string) => {
    const setStripeDefaultPayment = httpsCallable(
      functions,
      'setStripeDefaultPaymentV2'
    )
    return await setStripeDefaultPayment({ cardId })
  }
}

const subscriptionService = new SubscriptionService()

export default subscriptionService
