import { Form, Formik, FormikProps } from 'formik'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import BreadCrumbs from '../components/BreadCrumbs'
import ErrorPage from '../components/ErrorPage'
import NewGroupForm from '../components/groups/NewGroupForm'
import Section from '../components/Sections'
import { useGroup } from '../hooks/useGroups'
import { useUser } from '../hooks/useUser'
import groupService from '../services/groupService'
import { GroupTDO } from '../types/groups'
import { queryKeys } from '../utils/config'
import { queryClient } from '../utils/tanstack-react-query'
import Modal from '../components/Modal'

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Group Name is required.')
    .test('len', 'Group name can only contain 44 characters', val => {
      if (val) {
        return val.length <= 44
      }

      return true
    }),
  studentNameInput: Yup.string(),
  studentEmailInput: Yup.string(),
  students: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string(),
    })
  ),
})

const invalidateGroupQueries = async () => {
  const invalidateGroupQuery = queryClient.invalidateQueries({
    queryKey: [queryKeys.groups.group],
  })
  const invalidateAllGroupsQuery = queryClient.invalidateQueries({
    queryKey: [queryKeys.groups.groups],
  })
  const invalidateGroupOptionsQuery = queryClient.invalidateQueries({
    queryKey: [queryKeys.groups.groupOptions],
  })

  await Promise.all([
    invalidateAllGroupsQuery,
    invalidateGroupOptionsQuery,
    invalidateGroupQuery,
  ])
}

const NewGroup = () => {
  const navigate = useNavigate()
  const [toggleDelete, setToggleDelete] = useState(false)
  const user = useUser()
  const { id } = useParams<{ id: string }>()
  const { data: group, isLoading, isError } = useGroup()

  const breadCrumbPages = [
    { name: 'Manage Groups', href: '/groups' },
    {
      name: id ? 'Update Group' : 'New Group',
      href: id ? `/groups/${id}` : '/new-group',
    },
  ]

  const initialValues: GroupTDO = {
    name: group?.name || '',
    studentNameInput: '',
    studentEmailInput: '',
    students: group?.students
      ? group.students.map(({ name, email }) => ({ name, email }))
      : [],
    isArchived: group?.isArchived ? group.isArchived : false,
  }

  const createGroup = useMutation({
    mutationFn: groupService.createGroup,
    onSuccess: async () => {
      await invalidateGroupQueries()

      toast.success('Group Successfully Created.')

      navigate('/groups')
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  const updateGroup = useMutation({
    mutationFn: groupService.updateGroup,
    onSuccess: async () => {
      await invalidateGroupQueries()

      toast.success('Group Successfully Updated.')
      navigate('/groups')
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  const deleteGroup = useMutation({
    mutationFn: groupService.deleteGroup,
    onSuccess: async () => {
      await invalidateGroupQueries()

      toast.success('Group Successfully Deleted.')

      navigate('/groups')
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  const handleOnSubmit = async (data: GroupTDO) => {
    try {
      if (user.data?.id) {
        if (id) {
          await updateGroup.mutateAsync({ ...data, groupId: id })
        } else {
          await createGroup.mutateAsync({ ...data, userId: user.data.id })
        }
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: NewGroup.tsx ~ line 53 ~ handleOnSubmit ~ error',
        error
      )
    }
  }

  if (isError) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }

  return (
    <div className="container flex flex-col mx-auto  pb-12">
      <Modal
        title="Delete Group"
        description={
          <p>
            This action is irreversible.{' '}
            <strong>
              Your group and all projects related to this group will be
              permanently deleted
            </strong>
            . Are you sure you want to continue?
          </p>
        }
        toggle={toggleDelete}
        setToggle={setToggleDelete}
      >
        <div className="flex gap-3 mt-8">
          <button
            className="btn btn-white w-full text-center block"
            onClick={() => setToggleDelete(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-delete w-full text-center block"
            onClick={async () => await deleteGroup.mutateAsync(group?.id || '')}
          >
            Delete
          </button>
        </div>
      </Modal>
      <BreadCrumbs breadCrumbPages={breadCrumbPages} />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, values, setFieldValue }: FormikProps<GroupTDO>) => {
          return (
            <Form>
              <Section.Container>
                <Section.Header
                  title={id ? 'Update Group' : 'New Group'}
                  description={`Input your Group list here, include an email address for them if you'd like to be able to email summative assessment to them later (you can always print this if you don't want to use email). Groups are used when creating projects to identify the students to be marked.`}
                >
                  <Link to="/groups" className="text-link-black mr-7">
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-dark-purple"
                    disabled={!values.name || values.students.length === 0}
                  >
                    {id ? 'Update Group' : 'Create Group'}
                  </button>
                  {id && group?.isArchived && (
                    <button
                      onClick={() => setToggleDelete(true)}
                      type="button"
                      className="btn btn-delete ml-7"
                      disabled={updateGroup.isPending || deleteGroup.isPending}
                    >
                      Delete Group
                    </button>
                  )}
                </Section.Header>
                <NewGroupForm
                  isSubmitting={isSubmitting || isLoading}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Section.Container>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default NewGroup
