/* This example requires Tailwind CSS v2.0+ */

import { HomeIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'

type BreadCrumbsProps = {
  breadCrumbPages: {
    name: string
    href: string
  }[]
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ breadCrumbPages }) => {
  return (
    <nav className="flex my-6 print:hidden" aria-label="Breadcrumb">
      <ol className="flex flex-wrap items-center gap-4">
        <li>
          <div>
            <Link to="/projects" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {breadCrumbPages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <NavLink
                to={page.href}
                end
                className={({ isActive }) =>
                  clsx({
                    'ml-4 text-sm font-medium hover:text-gray-700': true,
                    'text-gray-500': !isActive,
                    'text-purple-700': isActive,
                  })
                }
              >
                {page.name}
              </NavLink>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default BreadCrumbs
