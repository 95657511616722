import clsx from 'clsx'
import { FieldProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { Direction, getTrackBackground, Range } from 'react-range'
import { displayIcon } from '../utils/markUtils'

type InputRangeProps = {
  label: string
  values: number | null
  onFinalChange: (values: number[]) => void
  evaluation?: string
  disabled?: boolean
}

const InputRange: React.ComponentType<FieldProps & InputRangeProps> = ({
  label,
  onFinalChange,
  values,
  evaluation,
  disabled,
  field: { name },
}) => {
  const [val, setVal] = useState(values === null ? [0] : [values])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (values !== null && val[0] !== values) {
        onFinalChange(val)
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val])

  const displayColor = () => {
    switch (name) {
      case 'research':
        return '#f97316'
      case 'concepts':
        return '#eab308'
      case 'failAndFix':
        return '#22c55e'
      case 'communicate':
        return '#3b82f6'
      case 'reflect':
        return '#ec4899'
      default:
        return '#eab308'
    }
  }

  return (
    <div className="inline-flex flex-col items-center ">
      <div className="mt-1 flex rounded-md shadow-sm mb-3">
        <div className="relative flex items-stretch flex-grow focus-within:z-10 border rounded-l-md p-2  border-gray-300 border-r-0">
          <input
            disabled={values === null}
            className="text-xs sm:text-md w-6 sm:w-5 number-as-text disabled:text-gray-300 outline-none disabled:bg-transparent"
            type="number"
            min="0"
            max="100"
            onFocus={e => e.target.select()}
            value={values !== null ? val[0] : 0}
            onChange={e => {
              const value = Math.floor(Number(e.target.value))

              if (value < 0) {
                return setVal([0])
              }

              if (value > 100) {
                return setVal([100])
              }
              setVal([value])
            }}
          />
        </div>
        <div className="pr-2 sm:pr-0 text-xs sm:w-9 -ml-px relative flex justify-center items-center py-2 border-l-0 sm:border-l border border-gray-300 font-medium rounded-r-md text-gray-700 sm:bg-gray-50">
          <span className="font-bold">{evaluation || '-'}</span>
        </div>
      </div>

      <Range
        direction={Direction.Up}
        values={val}
        min={0}
        max={100}
        // onFinalChange={onFinalChange}
        onChange={val => setVal(val)}
        disabled={values === null || disabled}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className="flex justify-center h-[154px] w-9 py-3"
          >
            <div
              ref={props.ref}
              style={{
                background: getTrackBackground({
                  values: val,
                  colors: [displayColor(), '#e5e7eb'],
                  min: 0,
                  max: 100,
                  direction: Direction.Up,
                  rtl: false,
                }),
              }}
              className="w-2 rounded-full h-full"
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            className={clsx({
              'border w-6 h-6 rounded-full outline-none bg-white': true,
              'border-gray-200': values === null,
              'border-orange-500': label === 'Research' && isDragged,
              'border-yellow-500': label === 'Concepts' && isDragged,
              'border-green-500': label === 'Fail & Fix' && isDragged,
              'border-blue-500': label === 'Communicate' && isDragged,
              'border-pink-500': label === 'Reflect' && isDragged,
            })}
          ></div>
        )}
      />

      <label
        htmlFor={name}
        className={clsx({
          'font-medium flex flex-col items-center mt-2 text-xs sm:text-md':
            true,
          'text-gray-800': values !== null,
          'text-gray-300': values === null,
        })}
      >
        {displayIcon({ active: values !== null, check: name })}
        {label}
      </label>
    </div>
  )
}

export default InputRange
