import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import Banner from '../components/Banner'
import ErrorPage from '../components/ErrorPage'
import ProjectAccordion from '../components/projects/ProjectAccordion'
import { useUser } from '../hooks/useUser'
import groupService from '../services/groupService'
import projectService from '../services/projectService'
import { Project } from '../types/projects'
import { queryKeys } from '../utils/config'
import { Toggle } from '../components/Toggle'

const Projects = () => {
  const user = useUser()
  const [isShowArchived, setIsShowArchived] = useState(false)
  const {
    data: groups,
    isLoading: isGroupsLoading,
    isError: isGroupError,
  } = useQuery({
    queryKey: [queryKeys.groups.groups],
    queryFn: () => groupService.getGroups(user.data?.id),
    select: groups => groups.filter(group => !group.isArchived),
    enabled: !!user.data,
  })

  const {
    data: projects,
    isLoading: isProjectsLoading,
    isError: isProjectsError,
  } = useQuery({
    queryKey: [queryKeys.projects.projects],
    queryFn: () => projectService.getProjects(user.data?.id),
    enabled: !!user.data && !!groups,
    select: result => {
      if (!result) return undefined

      const projectSections: { [key: string]: Project[] } = {}
      result.forEach(project => {
        if (!projectSections[project.qualificationName])
          projectSections[project.qualificationName] = []
        projectSections[project.qualificationName].push(project)
      })

      return projectSections
    },
  })

  if (isGroupsLoading || isProjectsLoading) {
    return (
      <>
        <div className="flex flex-col px-28 py-12 bg-gray-100 h-[250px] -mx-4 sm:-mx-6 lg:-mx-8 gap-3">
          <span className="rounded-lg overflow-hidden w-3/12 h-12 block animate-pulse bg-gray-200" />
          <span className="rounded-lg overflow-hidden w-10/12 h-12 block animate-pulse bg-gray-200" />
          <span className="rounded-lg overflow-hidden w-40 h-12 block animate-pulse bg-gray-200" />
        </div>
        <div className="container flex flex-col mx-auto mt-16  pb-12">
          <div className="flex flex-col gap-3">
            {Array.from(Array(4).keys()).map(key => (
              <div className="w-full" key={key}>
                <span className="rounded-lg overflow-hidden w-full h-12 block animate-pulse bg-gray-200" />
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }

  if (isProjectsError || isGroupError) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }

  if (!groups || groups.length === 0) {
    return (
      <div className="container flex flex-col mx-auto mt-16 pb-12">
        <div className="mx-auto flex px-6 py-14 max-w-2xl bg-orange-500 mt-12 rounded-md border border-gray-200 items-center gap-5">
          <div>
            <h1 className="font-semibold text-lg mb-2">
              Get ready for some Stellar Assessment!
            </h1>
            <p>
              To get started, add a class list to create your first group. Once
              this is done, you'll be able to make a project to assign to them.
            </p>

            <Link to="/new-group" className="btn btn-primary mt-8 px-12">
              Create Group
            </Link>
          </div>

          <img
            src="/img/brua_welcome.png"
            className="object-contain hidden lg:block max-w-md h-[200px]"
            alt="banner"
          />
        </div>
      </div>
    )
  }

  const title = 'My Projects'
  const subtitle =
    'Here you can list all the projects you’re working on, specify their qualification, select marking criteria and assign students that should be assessed at the end of each project.'

  if (projects) {
    return (
      <>
        <Banner
          title={title}
          subtitle={subtitle}
          buttonLink="/new-project"
          buttonText="New Projects"
          img="/img/brua_my_projects.png"
        />
        <div className="container flex flex-col mx-auto mt-16 pb-12">
          <Toggle
            label="Show Archived"
            description="Toggle this switch to see your archived projects."
            value={isShowArchived}
            onChange={() => setIsShowArchived(prev => !prev)}
          />
          {Object.values(projects).map((project, index) => {
            const projectToShow = project.filter(project =>
              isShowArchived ? true : !project.isArchived
            )
            if (projectToShow.length <= 0) {
              return undefined
            }

            return (
              <React.Fragment key={Object.keys(projects)[index]}>
                <ProjectAccordion projects={projectToShow} groups={groups} />
              </React.Fragment>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <>
      <Banner
        title={title}
        subtitle={subtitle}
        buttonLink="/new-project"
        buttonText="New Projects"
        img="/img/brua_my_projects.png"
      />
      <div className="container flex flex-col mx-auto mt-16 pb-12">
        <div className="card mx-auto text-center">
          <p className="font-medium mb-1">No Projects</p>
          <p className="light-text">
            Create your first project using the button below.
          </p>

          <div className="mt-6 mb-4 border-t border-gray-200" />
          <Link to="/new-project" className="btn btn-primary w-full block">
            New Project
          </Link>
        </div>
      </div>
    </>
  )
}

export default Projects
