import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Alert from '../components/Alert'
import AuthLayoutHeader from '../components/auth/AuthLayoutHeader'
import RecoverPasswordForm from '../components/auth/RecoverPasswordForm'
import authService from '../services/authService'
import { RecoverPasswordTDO, SignInError } from '../types/auth-in'

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
})

const initialValues: RecoverPasswordTDO = {
  email: '',
}

const RecoverPassword: React.FC = () => {
  const [sent, setSent] = useState(false)
  const signIn = useMutation<void, SignInError, RecoverPasswordTDO>({
    mutationFn: authService.sendPasswordResetEmail,
    onError: () => {
      toast.error('Error occurred during the login process.')
    },
    onSuccess: () => {
      setSent(true)
    },
  })

  const handleOnSubmit = async (
    values: RecoverPasswordTDO,
    formikBag: FormikHelpers<RecoverPasswordTDO>
  ): Promise<void> => {
    try {
      await signIn.mutateAsync(values)
    } catch (e) {
      const error = e as SignInError
      switch (error.code) {
        case 'auth/user-not-found':
          formikBag.setFieldError('email', ' ')
          break
        case 'auth/too-many-requests':
          formikBag.setFieldError(
            'email',
            'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later'
          )
          break
      }
    }
  }

  return (
    <>
      <AuthLayoutHeader
        title="Forgot password?"
        subTitle={
          <p className="text-gray-600 mt-4">
            No worries, we’ll send you reset instructions.
          </p>
        }
      />

      <div className="mt-6">
        {sent && (
          <Alert
            onClick={() => setSent(false)}
            type="SUCCESS"
            message="Check your email and follow the instructions to recover your account."
          />
        )}

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting }: FormikProps<RecoverPasswordTDO>) => (
            <Form className="space-y-6" noValidate>
              <RecoverPasswordForm isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>

        <span className="text-gray-400 mt-6 text-center block">
          Back to{' '}
          <Link className="text-link" to="/auth/sign-in">
            Sign In
          </Link>
        </span>
      </div>
    </>
  )
}

export default RecoverPassword
