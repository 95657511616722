import { collection, doc, getDocs, query, where } from 'firebase/firestore'
import { Rubric } from '../types/qualifications'
import { qualificationCollection } from './firebase/collections'

class QualificationService {
  getQualifications = async (organisationId?: string) => {
    const q = query(
      qualificationCollection,
      where('organisation.id', 'in', [
        organisationId || null,
        process.env.REACT_APP_PUBLIC_ORGANISATION,
      ])
    )
    const ref = await getDocs(q)
    return ref.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }))
  }

  getRubrics = async (id?: string): Promise<Rubric[] | undefined> => {
    const qualificationRef = doc(qualificationCollection, id)
    const rubricRef = collection(qualificationRef, 'rubrics')
    const rubricsDocs = await getDocs(rubricRef)
    const rubrics: Rubric[] = []

    rubricsDocs.docs.map(rubric => {
      if (rubric.exists()) {
        rubrics.push({
          ...(rubric.data() as Rubric),
          id: rubric.id,
        })
      }

      return null
    })

    return rubrics
  }
}

const qualificationService = new QualificationService()

export default qualificationService
