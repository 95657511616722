import { FirebaseError } from '@firebase/util'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as Yup from 'yup'
import OrganisationUserForm from '../components/AddOrganisationUser/OrganisationUserForm'
import ErrorPage from '../components/ErrorPage'
import LoadingScreen from '../components/LoadingScreen'
import organisationService from '../services/organisationSerivce'
import { OrganisationUserTDO } from '../types/organisations'
import { queryKeys } from '../utils/config'

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
  firstName: Yup.string().required('First name is required.'),
  lastName: Yup.string().required('Last name is required.'),
  organisationId: Yup.string().required('Organisation is required.'),
  expiresAt: Yup.string().required('Expiry date is required.'),
})

const AddOrganisationUser = () => {
  const {
    data: organisationOptions,
    isError,
    isLoading,
  } = useQuery({
    queryKey: [queryKeys.organisations.organisations],
    queryFn: organisationService.getOrganisations,
    select: group =>
      group.map(({ name, id }) => ({
        value: id,
        label: name,
      })),
  })

  const createOrganisationUser = useMutation({
    mutationFn: organisationService.createOrganisationUser,
    onError: (error: FirebaseError) => {
      toast.error(error.message)
    },
    onSuccess: () => {
      toast.success('Account Created.')
    },
  })

  if (isLoading) return <LoadingScreen />

  if (isError || !organisationOptions) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }

  const handleOnSubmit = async (
    { expiresAt, ...rest }: OrganisationUserTDO,
    formikBag: FormikHelpers<OrganisationUserTDO>
  ) => {
    const formatDate = new Date(expiresAt).getTime() / 1000
    await createOrganisationUser.mutateAsync({
      ...rest,
      expiresAt: formatDate,
    })

    formikBag.resetForm()
  }

  const initialValues: OrganisationUserTDO = {
    email: '',
    firstName: '',
    lastName: '',
    organisationId: organisationOptions ? organisationOptions[0].value : '',
    expiresAt: '',
  }

  return (
    <div className="mt-16 w-1/3">
      <h1 className="section-title">Add User</h1>
      <p className="mt-1 max-w-2xl text-sm text-gray-500 mb-6">
        Add an organisation user that was signed up offline.
      </p>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting }: FormikProps<OrganisationUserTDO>) => (
            <Form className="space-y-6" noValidate>
              <OrganisationUserForm
                isSubmitting={isSubmitting}
                organisationOptions={organisationOptions}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AddOrganisationUser
