import { Form, Formik, FormikProps } from 'formik'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import BreadCrumbs from '../components/BreadCrumbs'
import ErrorPage from '../components/ErrorPage'
import NewProjectForm from '../components/projects/NewProjectForm'
import Section from '../components/Sections'
import { useProject } from '../hooks/useProjects'
import { useUser } from '../hooks/useUser'
import projectService from '../services/projectService'
import { ProjectTDO } from '../types/projects'
import { queryKeys } from '../utils/config'
import { queryClient } from '../utils/tanstack-react-query'
import Modal from '../components/Modal'

const schema = Yup.object().shape({
  projectName: Yup.string().required('Project Name is required.'),
  qualificationName: Yup.string().required(),
  qualification: Yup.string().required('Qualification is required.'),
  group: Yup.string().required('Group is required.'),
  research: Yup.boolean(),
  concepts: Yup.boolean(),
  failAndFix: Yup.boolean(),
  communicate: Yup.boolean(),
  reflect: Yup.boolean(),
})

const invalidateProjectQueries = async () => {
  const projectInvalidate = queryClient.invalidateQueries({
    queryKey: [queryKeys.projects.project],
  })
  const allProjectsInvalidate = queryClient.invalidateQueries({
    queryKey: [queryKeys.projects.projects],
  })

  await Promise.all([projectInvalidate, allProjectsInvalidate])
}

const NewProject = () => {
  const autoFillgroup = localStorage.getItem('autofill-group')
  const [toggleDelete, setToggleDelete] = useState(false)
  const navigate = useNavigate()
  const user = useUser()
  const { id } = useParams<{ id: string }>()
  const { data: project, isLoading, isError } = useProject()

  const breadCrumbPages = [
    { name: 'Manage Projects', href: '/projects' },
    {
      name: id ? 'Update Project' : 'New Project',
      href: id ? `/projects/${id}` : '/new-project',
    },
  ]

  const initialValues: ProjectTDO = {
    projectName: project?.projectName || '',
    qualificationName: project?.qualificationName || '',
    qualification: project?.qualification || '',
    group: project?.group || autoFillgroup || '',
    feedback: Boolean(project?.feedback),
    research: project?.markingAreas
      ? project.markingAreas.includes('research')
      : true,
    concepts: project?.markingAreas
      ? project.markingAreas.includes('concepts')
      : true,
    failAndFix: project?.markingAreas
      ? project.markingAreas.includes('failAndFix')
      : true,
    communicate: project?.markingAreas
      ? project.markingAreas.includes('communicate')
      : true,
    reflect: project?.markingAreas
      ? project.markingAreas.includes('reflect')
      : true,
    isArchived: project?.isArchived ? project.isArchived : false,
  }

  useEffect(() => {
    localStorage.removeItem('autofill-group')
  }, [])

  const createProject = useMutation({
    mutationFn: projectService.createProject,
    onSuccess: async () => {
      await invalidateProjectQueries()

      toast.success('Project Successfully Created.')

      navigate('/projects')
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  const updateProject = useMutation({
    mutationFn: projectService.updateProject,
    onSuccess: async () => {
      await invalidateProjectQueries()

      toast.success('Project Successfully Updated.')

      navigate('/projects')
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  const deleteProject = useMutation({
    mutationFn: projectService.deleteProject,
    onSuccess: async () => {
      await invalidateProjectQueries()

      toast.success('Project Successfully Deleted.')

      navigate('/projects')
    },
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
  })

  const handleOnSubmit = async (data: ProjectTDO) => {
    try {
      if (user.data?.id) {
        if (id) {
          await updateProject.mutateAsync({ ...data, projectId: id })
        } else {
          await createProject.mutateAsync({ ...data, userId: user.data.id })
        }
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: NewProject.tsx ~ line 127 ~ handleOnSubmit ~ error',
        error
      )
    }
  }

  if (isError) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }

  return (
    <div className="container flex flex-col mx-auto  pb-12">
      <Modal
        title="Delete Project"
        description={
          <p>
            This action is irreversible.{' '}
            <strong>
              Your project and all associated data will be permanently deleted
            </strong>
            . Are you sure you want to continue?
          </p>
        }
        toggle={toggleDelete}
        setToggle={setToggleDelete}
      >
        <div className="flex gap-3 mt-8">
          <button
            className="btn btn-white w-full text-center block"
            onClick={() => setToggleDelete(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-delete w-full text-center block"
            onClick={async () =>
              await deleteProject.mutateAsync(project?.id || '')
            }
          >
            Delete
          </button>
        </div>
      </Modal>
      <BreadCrumbs breadCrumbPages={breadCrumbPages} />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, values, setFieldValue }: FormikProps<ProjectTDO>) => {
          const isSubmitEnabled =
            (values.projectName &&
              values.qualification &&
              values.group &&
              (values.research ||
                values.concepts ||
                values.failAndFix ||
                values.communicate ||
                values.reflect)) ||
            values.isArchived

          return (
            <Form>
              <Section.Container>
                <Section.Header
                  title={id ? 'Update Project' : 'New Project'}
                  description="Input your challenge name here, select the qualification they are working towards and tell us which of your groups is working on it."
                >
                  <Link to="/projects" className="text-link-black mr-7">
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!isSubmitEnabled || updateProject.isPending}
                  >
                    {id ? 'Update Project' : 'Create Project'}
                  </button>
                  {id && project?.isArchived && (
                    <button
                      onClick={() => setToggleDelete(true)}
                      type="button"
                      className="btn btn-delete ml-7"
                      disabled={
                        updateProject.isPending || deleteProject.isPending
                      }
                    >
                      Delete Project
                    </button>
                  )}
                </Section.Header>
                <NewProjectForm
                  isSubmitting={isSubmitting || isLoading}
                  values={values}
                  setFieldValue={setFieldValue}
                  haveStudentsBeenEdited={Boolean(
                    project?.haveStudentsBeenEdited
                  )}
                />
              </Section.Container>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default NewProject
