export const APP_TITLE = 'Stellar Assessment Tool'

export enum markingAreasType {
  research = 'research',
  concepts = 'concepts',
  failAndFix = 'failAndFix',
  communicate = 'communicate',
  reflect = 'reflect',
  overall = 'overall',
}

export enum userRoles {
  endUser = 'END_USER',
  admin = 'ADMIN',
}

export const queryKeys = {
  stripe: {
    mySubscription: 'mySubscription',
    product: 'stripeProduct',
    session: 'stripeSession',
    cards: 'stripeCards',
  },
  groups: {
    groupOptions: 'groupOptions',
    group: 'group',
    groups: 'groups',
  },
  projects: {
    project: 'project',
    projects: 'projects',
    groupProjects: 'groupProjects',
  },
  qualifications: {
    qualificationOptions: 'qualificationOptions',
    rubric: 'rubric',
  },
  frontPage: {
    faqs: 'faqs',
  },
  organisations: {
    organisations: 'organisations',
  },
}
