import React from 'react'
import BreadCrumbs from '../components/BreadCrumbs'
import ErrorPage from '../components/ErrorPage'
import { useGroup } from '../hooks/useGroups'
import { StudentWithGrades } from '../types/groups'
import { useProjectsByGroup } from '../hooks/useProjectsByGroup'
import { uniqBy } from 'lodash'
import Table from '../components/Table'
import { createColumnHelper } from '@tanstack/react-table'

function GroupMarks() {
  const {
    data: group,
    isLoading: isGroupLoading,
    isError: isGroupError,
  } = useGroup()
  const {
    data: projects,
    isLoading: isProjectsLoading,
    isError: isProjectsError,
  } = useProjectsByGroup()

  const studentsWithGrades = group?.students.map(student => {
    const projectsForCurrentStudent = projects
      ?.filter(project => !project.isArchived)
      .map(project => {
        const studentMarks = project.students.find(projectStudent => {
          return (
            student.email === projectStudent.email &&
            student.name === projectStudent.name
          )
        })

        return {
          projectName: project.projectName,
          projectId: project.id,
          overall: studentMarks?.overall || -1,
        }
      })
    return {
      name: student.name,
      projects: projectsForCurrentStudent || [],
    }
  })

  const allProjects = uniqBy(
    studentsWithGrades?.flatMap(studentWithGrades => {
      return studentWithGrades.projects
    }),
    'projectId'
  )

  const columnHelper = createColumnHelper<StudentWithGrades>()

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        enableSorting: true,
      }),
      ...allProjects.map(project => {
        return columnHelper.accessor('projects', {
          id: `${project.projectId}`,
          enableSorting: false,
          header: project.projectName,
          cell: info => {
            const studentProjects = info.getValue()

            const currentProject = studentProjects.find(
              studentProject => studentProject.projectId === project.projectId
            )

            // this should never happen
            if (!currentProject) {
              return 'N/A'
            }

            const display =
              currentProject?.overall >= 0 ? currentProject?.overall : 'N/A'

            return display
          },
        })
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [studentsWithGrades]
  )

  const isLoading = isProjectsLoading || isGroupLoading

  const isError = isProjectsError || isGroupError

  if (isLoading) return null

  if (isError || !group || !projects) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }
  const breadCrumbPages = [
    { name: 'Manage Groups', href: '/groups' },
    { name: `${group.name} marks`, href: `/groups/${group.id}/marks` },
  ]

  return (
    <div className="container flex flex-col mx-auto items-start pb-12">
      <BreadCrumbs breadCrumbPages={breadCrumbPages} />
      <h1 className="section-title">{group.name}</h1>
      <p className="landing-light-text">Final assessment sheet</p>
      <div className="w-full mt-12">
        {group.students.length > 0 && projects.length > 0 ? (
          <Table
            columns={columns}
            data={Array.isArray(studentsWithGrades) ? studentsWithGrades : []}
            isColumnsEqualWidth
          />
        ) : (
          <div className="card text-center">
            <p className="page-title mb-1">
              No Students Or Projects Added To This Group
            </p>
            <p className="light-text">
              To begin, add students and/or projects.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export { GroupMarks }
