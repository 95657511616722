import React from 'react'
import { Link } from 'react-router-dom'
import { AllOrNothing } from '../utils/type-helper'

type BannerProps = {
  title: string
  subtitle: string
  img: string
} & AllOrNothing<{ buttonText: string; buttonLink: string }>

const Banner: React.FC<BannerProps> = ({
  subtitle,
  title,
  buttonLink,
  buttonText,
  img,
}) => (
  <div className="flex px-28 py-12 bg-orange-500 -mx-4 sm:-mx-6 lg:-mx-8">
    <div className="max-w-xl">
      <h1 className="page-tile mb-4">{title}</h1>
      <p>{subtitle}</p>
      {buttonText && buttonLink && (
        <Link to={buttonLink} className="btn btn-primary mt-4">
          {buttonText}
        </Link>
      )}
    </div>

    <img
      src={img}
      className="object-contain hidden lg:block max-w-md h-[200px] mx-auto"
      alt="banner"
    />
  </div>
)

export default Banner
