import { FirebaseError } from '@firebase/util'
import { FastField, Form, Formik, FormikProps } from 'formik'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import * as Yup from 'yup'
import PaymentCards from '../components/Account/PaymentCards'
import Subscription from '../components/Account/Subscription'
import UserForm from '../components/Account/UserForm'
import FormikInput from '../components/FormikInput'
import Modal from '../components/Modal'
import Section from '../components/Sections'
import Spinner from '../components/Spinner'
import { useUser } from '../hooks/useUser'
import authService from '../services/authService'
import { RegisterTDO } from '../types/auth-in'

type UpdateUserTDO = Pick<RegisterTDO, 'email' | 'firstName' | 'lastName'>
type UpdatePasswordTDO = { password: string }

const userSchema = Yup.object().shape({
  firstName: Yup.string().required('Fist name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Must be a valid email').required(),
})

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must 8 characters, at least one letter, one number and one special character'
    )
    .required(),
})

const confirmSchema = Yup.object().shape({
  password: Yup.string().required(),
})

const passwordInitialValues: UpdatePasswordTDO = {
  password: '',
}

const Account = () => {
  const user = useUser()
  const [toggleDelete, setToggleDelete] = useState(false)
  const [toggleConfirmation, setToggleConfirmation] = useState(false)

  const updateUser = useMutation({
    mutationFn: authService.updateUser,
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
    onSuccess: () => {
      toast.success('Account Updated.')
    },
  })

  const refreshSession = useMutation({
    mutationFn: authService.refreshSession,
    onError: () => {
      toast.error('Wrong Credentials.')
    },
    onSuccess: () => {
      setToggleConfirmation(false)
      toast.success('Account Confirmed.')
    },
  })

  const changePassword = useMutation({
    mutationFn: authService.updatePassword,
    onError: (error: FirebaseError) => {
      if (error.code === 'auth/requires-recent-login') {
        setToggleConfirmation(true)
      } else {
        toast.error(
          'Something went wrong, if the error persist please contact support.'
        )
      }
    },
    onSuccess: () => {
      toast.success('Password Updated.')
    },
  })

  const requestMyData = useMutation({
    mutationFn: authService.requestMyData,
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
    onSuccess: () => {
      toast.success(
        'Request successful. Your exported CSV is being prepared and will be sent to your email shortly'
      )
    },
  })

  const deleteUser = useMutation({
    mutationFn: authService.deleteUser,
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
    onSuccess: () => {
      toast.success('User Deleted.')
      authService.logout()
    },
  })

  if (!user.data) return null

  const userInitialValues: UpdateUserTDO = {
    firstName: user.data.firstName,
    lastName: user.data.lastName,
    email: user.data.email,
  }

  const handleOnSubmitUser = async (data: UpdateUserTDO) => {
    await updateUser.mutateAsync(data)
  }
  const handleOnSubmitPassword = async (data: UpdatePasswordTDO) => {
    await changePassword.mutateAsync(data.password)
  }

  const handleOnConfirmSession = async (data: UpdatePasswordTDO) => {
    await refreshSession.mutate(data.password)
  }

  return (
    <div className="container mx-auto flex mt-12 pb-12">
      <Modal
        title="Confirmation"
        description="You need to re-enter your credentials to proceed with these changes."
        toggle={toggleConfirmation}
        setToggle={setToggleConfirmation}
      >
        <Formik
          enableReinitialize
          initialValues={{ password: '' }}
          validationSchema={confirmSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleOnConfirmSession}
        >
          {({ isSubmitting }: FormikProps<UpdatePasswordTDO>) => (
            <Form className="w-full flex flex-col gap-6" noValidate>
              <fieldset className="space-y-3 w-full" disabled={isSubmitting}>
                <FastField
                  type="password"
                  name="password"
                  component={FormikInput}
                  label="Password"
                />
              </fieldset>
              <div className="flex gap-3 mt-2">
                <button
                  type="button"
                  className="btn btn-white w-full text-center block"
                  onClick={() => setToggleConfirmation(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary w-full text-center block"
                >
                  Confirm
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        title="Delete Account"
        description="This acction will be irreversible, your account and all your data will be deleted, are you sure you want to continue?"
        toggle={toggleDelete}
        setToggle={setToggleDelete}
      >
        <div className="flex gap-3 mt-8">
          <button
            className="btn btn-white w-full text-center block"
            onClick={() => setToggleDelete(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-delete w-full text-center block"
            onClick={async () => await deleteUser.mutateAsync()}
          >
            Delete
          </button>
        </div>
      </Modal>
      <div className="w-full">
        <Section.Container>
          <Section.Header
            title="Account"
            description="Update and manage your account details here."
          ></Section.Header>
          {!user.data.organisation && <Subscription user={user.data} />}
          <Section.Body>
            <Section.Item
              label="Your Details"
              description="Update your details"
            >
              <Formik
                enableReinitialize
                initialValues={userInitialValues}
                validationSchema={userSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleOnSubmitUser}
              >
                {({ isSubmitting }: FormikProps<UpdateUserTDO>) => (
                  <Form
                    className="w-full flex flex-col lg:flex-row gap-6"
                    noValidate
                  >
                    <UserForm isSubmitting={isSubmitting} />
                  </Form>
                )}
              </Formik>
            </Section.Item>
            <Section.Item
              label="Your Password"
              description="Update your password"
            >
              <Formik
                enableReinitialize
                initialValues={passwordInitialValues}
                validationSchema={passwordSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleOnSubmitPassword}
              >
                {({ isSubmitting }: FormikProps<UpdatePasswordTDO>) => (
                  <Form
                    className="w-full flex flex-col lg:flex-row gap-6"
                    noValidate
                  >
                    <fieldset
                      className="space-y-3 w-full lg:w-96"
                      disabled={isSubmitting}
                    >
                      <FastField
                        type="password"
                        name="password"
                        component={FormikInput}
                        label="Password"
                      />
                      <p className="light-text">
                        Must contain six characters, at least one letter, one
                        number and one special character
                      </p>
                    </fieldset>
                    <button
                      type="submit"
                      className="btn btn-primary whitespace-nowrap lg:self-start lg:mt-6 btn-full lg:w-auto"
                      disabled={isSubmitting}
                    >
                      Update Password
                    </button>
                  </Form>
                )}
              </Formik>
            </Section.Item>
            {!user.data.organisation && (
              <Section.Item
                label="Card Details"
                description="Update your details"
              >
                <PaymentCards />
              </Section.Item>
            )}
            <Section.Item label="Your Data" description="Manage your data">
              <div className="flex items-center">
                <button
                  className="btn btn-primary mr-3"
                  onClick={() => requestMyData.mutate()}
                  disabled={requestMyData.isPending}
                >
                  {requestMyData.isPending ? (
                    <span className="flex items-center">
                      <Spinner className="h-4 w-4 trailing-icon" />{' '}
                      Requesting...
                    </span>
                  ) : (
                    ' Request a copy of my data'
                  )}
                </button>
                <button
                  className="btn btn-secondary-delete"
                  onClick={() => setToggleDelete(true)}
                >
                  Delete my account and my data
                </button>
              </div>
            </Section.Item>
          </Section.Body>
        </Section.Container>
      </div>
    </div>
  )
}

export default Account
