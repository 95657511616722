import { GridLabelProps } from '@nivo/radar'
import Communicate from './MarkIcons/Communicate'
import Concepts from './MarkIcons/Concepts'
import FailAndFix from './MarkIcons/FailAndFix'
import Reflect from './MarkIcons/Reflect'
import Research from './MarkIcons/Research'

const RadarLabel = (label: GridLabelProps) => {
  const icon = () => {
    switch (label.id) {
      case 'Research':
        return <Research dy="0.5em" textAnchor={label.anchor} />
      case 'Concepts':
        return <Concepts dy="0.5em" textAnchor={label.anchor} />
      case 'Fail & Fix':
        return <FailAndFix dy="0.5em" textAnchor={label.anchor} />
      case 'Communicate':
        return <Communicate dy="0.5em" textAnchor={label.anchor} />
      case 'Reflect':
        return <Reflect dy="0.5em" textAnchor={label.anchor} />

      default:
        return <Research dy="0.5em" textAnchor={label.anchor} />
    }
  }
  return (
    <g key={label.id} transform={`translate(${label.x - 14}, ${label.y - 14})`}>
      {icon()}
    </g>
  )
}

export default RadarLabel
