import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import React from 'react'

type AlertProps = {
  message: string
  type: 'INFO' | 'ALERT' | 'SUCCESS' | 'ERROR'
  onClick: () => void
}

const Alert: React.FC<AlertProps> = ({ message, type, onClick }) => (
  <div className="rounded-md bg-green-50 p-4 mb-6">
    <div className="flex">
      <div className="flex-shrink-0">
        <CheckCircleIcon
          className={clsx({
            'h-5 w-5': true,
            'text-red-400': type === 'ERROR',
            'text-green-400': type === 'SUCCESS',
            'text-blue-400': type === 'INFO',
            'text-yellow-400': type === 'ALERT',
          })}
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        <p
          className={clsx({
            'text-sm font-medium': true,
            'text-red-800': type === 'ERROR',
            'text-green-800': type === 'SUCCESS',
            'text-blue-800': type === 'INFO',
            'text-yellow-800': type === 'ALERT',
          })}
        >
          {message}
        </p>
      </div>
      <div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button
            onClick={onClick}
            type="button"
            className={clsx({
              'inline-flex  rounded-md p-1.5   focus:outline-none focus:ring-2 focus:ring-offset-2 ':
                true,
              'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600':
                type === 'ERROR',
              'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600':
                type === 'SUCCESS',
              'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600':
                type === 'INFO',
              'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600':
                type === 'ALERT',
            })}
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default Alert
