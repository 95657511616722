import { FieldProps, getIn } from 'formik'
import React from 'react'
import Select from 'react-select'
import { reactSelectStyles, reactSelectTheme } from '../utils/reactSelect'

export interface Option {
  label: string
  value: string
}

interface FormikSelectProps extends FieldProps {
  options: Option[]
  isMulti?: boolean
  label: string
  placeholder?: string
}

const FormikSelect: React.ComponentType<FormikSelectProps> = ({
  field,
  form,
  label,
  ...rest
}) => {
  const error = getIn(form.errors, field.name)
  const isTouched = getIn(form.touched, field.name)
  const hasError = isTouched && error

  const onChange = (option: Option | Option[]) => {
    form.setFieldValue(
      field.name,
      rest.isMulti
        ? (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value
    )
  }

  const getValue = (): any => {
    if (rest.options) {
      return rest.isMulti
        ? rest.options.filter(option => field.value.indexOf(option.value) >= 0)
        : rest.options.find(option => option.value === field.value)
    } else {
      return rest.isMulti ? [] : ('' as any)
    }
  }

  return (
    <div>
      <label
        htmlFor={field.name}
        className="mb-1 block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <Select
        styles={reactSelectStyles}
        theme={reactSelectTheme}
        {...rest}
        onChange={onChange}
        value={getValue()}
      />

      {hasError ? (
        <p className="mt-1 text-xs text-red-900" id={`${field.name}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  )
}

export default FormikSelect
