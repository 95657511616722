import { FastField } from 'formik'
import React from 'react'
import FormikInput from '../FormikInput'
import Spinner from '../Spinner'

type SignInFormProps = {
  isSubmitting: boolean
}

const SignInForm: React.FC<SignInFormProps> = ({ isSubmitting }) => (
  <>
    <fieldset disabled={isSubmitting}>
      <div className="space-y-3">
        <FastField
          type="email"
          name="email"
          component={FormikInput}
          label="Email"
          autoComplete="username"
          autoFocus
        />
      </div>
    </fieldset>

    <fieldset disabled={isSubmitting}>
      <div className="space-y-3">
        <FastField
          type="password"
          name="password"
          component={FormikInput}
          label="Password"
        />
      </div>
    </fieldset>

    <div>
      <button className="btn btn-primary btn-full" disabled={isSubmitting}>
        {isSubmitting ? (
          <Spinner className="h-4 w-4 trailing-icon" />
        ) : (
          'Sign in'
        )}
      </button>
    </div>
  </>
)

export default SignInForm
