import React from 'react'

type SectionProps = {
  children?: React.ReactNode
}

type SectionHeader = {
  title: string
  description: string
  children?: React.ReactNode
}

type SectionBody = {
  children?: React.ReactNode
}

type SectionItem = {
  htmlFor?: string
  label: string
  description?: string
  children?: React.ReactNode
}

class section {
  Container: React.FC<SectionProps> = ({ children }) => (
    <div className="space-y-8 sm:space-y-5">{children}</div>
  )

  Header: React.FC<SectionHeader> = ({ children, title, description }) => (
    <div className="flex">
      <div className="flex-1">
        <h1 className="section-title">{title}</h1>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{description}</p>
      </div>

      <div className="max-w-md">{children}</div>
    </div>
  )

  Body: React.FC<SectionBody> = ({ children }) => (
    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">{children}</div>
  )

  Item: React.FC<SectionItem> = ({ children, htmlFor, label, description }) => (
    <>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 pt-5">
        <label
          htmlFor={htmlFor}
          className="block text-sm font-medium text-gray-700 sm:mt-px"
        >
          {label}
          {description && <p className="mt-1 light-text">{description}</p>}
        </label>
        <div className="mt-3 lg:mt-1 sm:mt-0 sm:col-span-2">{children}</div>
      </div>
    </>
  )
}

const Section = new section()

export default Section
