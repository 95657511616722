import { onAuthStateChanged, User } from 'firebase/auth'
import React, { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import authService from '../../services/authService'
import { auth } from '../../utils/firebase'
import LoadingScreen from '../LoadingScreen'

type AuthProps = {
  children?: React.ReactNode
}

const Auth: React.FC<AuthProps> = ({ children }: AuthProps) => {
  const [isLoading, setLoading] = useState(true)
  const queryClient = useQueryClient()

  const setUser = useCallback(
    async (userId?: string) => {
      try {
        const fullUser = await authService.getUser(userId)
        queryClient.setQueryData(['user'], fullUser)
      } catch {
        queryClient.setQueryData(['user'], null)
        authService.logout()
      }
    },
    [queryClient]
  )

  useEffect(() => {
    return onAuthStateChanged(auth, async (user: User | null) => {
      if (user) {
        await setUser(user.uid)
      } else {
        queryClient.setQueryData(['user'], null)
      }
      setLoading(false)
    })
  }, [queryClient, setUser])

  useEffect(() => {
    const initAuth = async () => {
      const token = await authService.getAccessToken()

      if (token) {
        await setUser()
      }
    }

    initAuth()
  }, [setUser])

  if (isLoading) {
    return <LoadingScreen />
  }

  return <>{children}</>
}

export default Auth
