import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import AuthLayoutHeader from '../components/auth/AuthLayoutHeader'
import SignInForm from '../components/auth/SignInForm'
import authService from '../services/authService'
import { SignInError, SignInTDO } from '../types/auth-in'
import { User } from '../types/user'

const SignIn: React.FC = () => {
  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .required('Email is required'),
    password: Yup.string().required(),
  })

  const initialValues: SignInTDO = {
    email: '',
    password: '',
  }

  const signIn = useMutation<User, SignInError, SignInTDO>({
    mutationFn: authService.signInWithEmailAndPassword,
    onError: () => {
      toast.error('Error occurred during the login process.')
    },
  })

  const handleOnSubmit = async (
    values: SignInTDO,
    formikBag: FormikHelpers<SignInTDO>
  ): Promise<void> => {
    try {
      await signIn.mutateAsync(values)
    } catch (e) {
      const error = e as SignInError
      switch (error.code) {
        case 'permission-denied':
          formikBag.setFieldError('email', ' ')
          break
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          formikBag.setFieldError('email', ' ')
          formikBag.setFieldError('password', 'Wrong credentials')
          break
        case 'auth/too-many-requests':
          formikBag.setFieldError(
            'email',
            'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later'
          )
          break
      }
    }
  }

  return (
    <>
      <AuthLayoutHeader title="Sign In" />

      <div className="mt-6">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting }: FormikProps<SignInTDO>) => (
            <Form className="space-y-6" noValidate>
              <SignInForm isSubmitting={isSubmitting} />

              <span className="text-gray-400 mt-6 text-center block">
                Don’t have an account?{' '}
                <Link className="text-link" to="/auth/register">
                  Create Account
                </Link>
              </span>
              <Link
                to="/auth/recovery"
                className="text-link block mt-3 text-center"
              >
                Forgot Password?
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default SignIn
