import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React from 'react'

type TableProps<DataType> = {
  data: DataType[] | undefined
  columns: ColumnDef<DataType, any>[]
  isColumnsEqualWidth?: boolean
}

const Table = <TableData extends {}>(props: TableProps<TableData>) => {
  const { data, columns, isColumnsEqualWidth } = props

  const table = useReactTable({
    data: data || [],
    initialState: {
      pagination: {
        pageSize: 50,
      },
    },
    defaultColumn: {
      size: 999, // it supposed to be auto, but needs to be a number
    },
    columns,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg border border-gray-200">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-secondary-50">
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      className="table-header select-none"
                      style={
                        isColumnsEqualWidth
                          ? {
                              width: `${100 / headerGroup.headers.length}%`, // Equally distribute the width
                            }
                          : {}
                      }
                    >
                      <span
                        role={header.column.getCanSort() ? 'button' : undefined}
                        tabIndex={header.column.getCanSort() ? 0 : undefined}
                        onKeyDown={
                          header.column.getCanSort()
                            ? header.column.getToggleSortingHandler()
                            : undefined
                        }
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointers select-none relative'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        className="flex items-center"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <span className="text-secondary-700 inline-block w-2">
                          {{
                            asc: '▲',
                            desc: '▼',
                          }[header.column.getIsSorted() as string] ?? null}
                        </span>
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {table.getRowModel().rows.map((row, i) => {
                return (
                  <tr key={row.id} className="even:bg-secondary-25">
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td
                          style={
                            isColumnsEqualWidth
                              ? {
                                  width: `${
                                    100 / row.getVisibleCells().length
                                  }%`, // Equally distribute the width
                                }
                              : {}
                          }
                          className="truncate whitespace-nowrap px-4 py-6 text-sm text-gray-500"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Table) as typeof Table
