import { StylesConfig, Theme } from 'react-select'

export const reactSelectTheme = (config: Theme) => ({
  ...config,
  borderRadius: 6,
  colors: {
    ...config.colors,
    primary: '#e61b74',
    primary75: '#ee5f9e',
    primary50: '#f5a4c7',
    primary25: '#f9c6dc',
    neutral0: 'white',
    neutral20: 'rgb(209 213 219)',
  },
})

export const reactSelectStyles: StylesConfig = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  control: (provided, { isDisabled }) => ({
    ...provided,
    '&:hover': { borderColor: 'rgb(209 213 219)' }, // border style on hover
    border: '1px solid rgb(209 213 219)', // default border color
    boxShadow: 'none', // no box-shadow
    backgroundColor: isDisabled ? '#f9fafb' : 'white',
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: '#6B7280',
      fontSize: 14,
      fontWeight: 400,
    }
  },
  menu: provided => ({
    ...provided,
    zIndex: 1000,
  }),
}
