import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Navigate } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import subscriptionService from '../../services/subscriptionService'
import { queryKeys } from '../../utils/config'
import LoadingScreen from '../LoadingScreen'

type SubGuardProps = {
  children?: React.ReactNode
}

const SubGuard: React.FC = ({ children }: SubGuardProps) => {
  const user = useUser()

  const {
    data: userSubscription,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: [queryKeys.stripe.mySubscription],
    queryFn: () => subscriptionService.getFirebaseSubscription(user.data?.id),
    enabled: !!user.data,
    staleTime: 0,
    gcTime: 0,
  })

  useEffect(() => {
    if (isLoading === false && isRefetching === false && !userSubscription) {
      setTimeout(() => {
        refetch()
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isRefetching])

  if (user.isLoading) {
    return <LoadingScreen />
  }

  if (!user.data || (user.data && user.data.organisation))
    return <Navigate to="/auth/sign-in" />

  if (isRefetching || isLoading || !userSubscription) return <LoadingScreen />

  if (userSubscription.subscription) {
    if (
      userSubscription.subscription >= Math.round(new Date().getTime() / 1000)
    ) {
      return <Navigate replace to="/projects" />
    }
  }

  return <>{children}</>
}

export default SubGuard
