import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion, Variant } from 'framer-motion'
import React, { useState } from 'react'

const variant = {
  open: {
    backgroundColor: '#FAF8FF',
    paddingBottom: 32,
    marginBottom: 0,
  },
  close: {
    backgroundColor: 'transparent',
    paddingBottom: 0,
    marginBottom: 32,
  },
}

const contentVariant = {
  open: {
    height: 'auto',
    marginTop: 8,
    transition: {
      type: 'tween',
    },
  } as Variant,
  close: {
    height: 0,
    marginTop: 0,
    transition: {
      type: 'tween',
    },
  } as Variant,
}

type AccordionProps = {
  question: string
  answer: string
}

const Accordion: React.FC<AccordionProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <motion.div
      className="w-full overflow-hidden rounded-lg px-8 pt-8 cursor-pointer"
      variants={variant}
      animate={isOpen ? 'open' : 'close'}
    >
      <div onClick={() => setIsOpen(!isOpen)}>
        <div className="text-lg flex justify-between items-center w-full">
          {question}
          <FontAwesomeIcon
            className="leading-icon w-5 h-5"
            icon={isOpen ? faMinusCircle : faPlusCircle}
          />
        </div>
      </div>

      <motion.div
        variants={contentVariant}
        animate={isOpen ? 'open' : 'close'}
        className="text-gray-500"
      >
        {answer}
      </motion.div>
    </motion.div>
  )
}

export default Accordion
