import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast'
import { QueryClientProvider as TanstackQueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from 'react-router-dom'
import Auth from '../components/auth/Auth'
import Routes from '../routes'
import { APP_TITLE } from '../utils/config'
import { queryClient as tanstackQueryClient } from '../utils/tanstack-react-query'

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s | ${APP_TITLE}`} defaultTitle={APP_TITLE} />
      <Toaster position="top-right" />

      <BrowserRouter>
        <TanstackQueryClientProvider client={tanstackQueryClient}>
          <ReactQueryDevtools initialIsOpen={false} />

          <Auth>
            <Routes />
          </Auth>
        </TanstackQueryClientProvider>
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default App
