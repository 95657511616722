import { useQuery } from '@tanstack/react-query'
import authService from '../services/authService'
import { User } from '../types/user'

export function useUser() {
  return useQuery<User | null>({
    queryKey: ['user'],
    queryFn: () => authService.getUser(),
  })
}
