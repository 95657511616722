import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import projectService from '../services/projectService'
import { queryKeys } from '../utils/config'

export function useProjectsByGroup() {
  const { id } = useParams<{ id: string }>()

  return useQuery({
    queryKey: [queryKeys.projects.groupProjects, id],
    queryFn: async () => await projectService.getProjectsForGroup(id),
    enabled: !!id,
  })
}
