import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import Banner from '../components/Banner'
import ErrorPage from '../components/ErrorPage'
import GroupCard from '../components/groups/GroupCard'
import { useUser } from '../hooks/useUser'
import groupService from '../services/groupService'
import { queryKeys } from '../utils/config'
import { Toggle } from '../components/Toggle'

const Groups = () => {
  const user = useUser()
  const [isShowArchived, setIsShowArchived] = useState(false)
  const {
    data: groups,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [queryKeys.groups.groups],
    queryFn: () => groupService.getGroups(user.data?.id),
    enabled: !!user.data,
  })

  const title = 'My Groups'
  const subtitle =
    'Here you can set up student group templates to help you create new projects quicker without manually assigning each student to the said project individually. You can think of this place as a stylised Excel sheet, helping speed up the process when necessary.'

  if (isLoading) {
    return (
      <div>
        <Banner
          title={title}
          subtitle={subtitle}
          buttonLink="/new-group"
          buttonText="New Group"
          img="/img/brua_my_groups.png"
        />
        <div className="grid grid-cols-1 w-full gap-4 place-items-center sm:place-items-start sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-12">
          {Array.from(Array(4).keys()).map(key => (
            <div
              key={key}
              style={{ height: 275 }}
              className="rounded-lg overflow-hidden w-full max-w-xs block animate-pulse bg-gray-200"
            >
              <span className="block h-24 w-full animate-pulse rounded bg-gray-300" />
            </div>
          ))}
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }

  const groupsToShow = groups?.filter(group =>
    isShowArchived ? true : !group.isArchived
  )

  return (
    <div>
      <Banner
        title={title}
        subtitle={subtitle}
        buttonLink="/new-group"
        buttonText="New Group"
        img="/img/brua_my_groups.png"
      />

      <div className="container mx-auto flex mt-12">
        <Toggle
          label="Show Archived"
          description="Toggle this switch to see your archived groups."
          value={isShowArchived}
          onChange={() => setIsShowArchived(prev => !prev)}
        />
      </div>

      <div className="container mx-auto flex mt-12 pb-12">
        {groupsToShow && groupsToShow.length > 0 ? (
          <GroupCard groups={groupsToShow} />
        ) : (
          <div className="card mx-auto text-center">
            <p className="font-medium mb-1">No Groups</p>
            <p className="light-text">
              Create your first Group using the button below.
            </p>

            <div className="mt-6 mb-4 border-t border-gray-200" />
            <Link to="/new-group" className="btn btn-primary w-full block">
              New Group
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default Groups
