import { ResponsiveRadar } from '@nivo/radar'
import React from 'react'
import RadarLabel from './RadarLabel'

type RadarProps = {
  data: {
    name: string
    mark: number
  }[]
}

const Radar: React.FC<RadarProps> = ({ data, ...rest }) => (
  <ResponsiveRadar
    data={data}
    maxValue={100}
    keys={['mark']}
    indexBy="name"
    valueFormat=">-.2f"
    margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
    borderColor="#a855f7"
    gridLabelOffset={36}
    dotSize={10}
    dotColor={{ theme: 'background' }}
    dotBorderWidth={2}
    colors={['#a855f7']}
    blendMode="multiply"
    motionConfig="slow"
    gridShape="linear"
    gridLabel={RadarLabel}
    enableDots={false}
    gridLevels={3}
    {...rest}
  />
)

export default Radar
