import { getDocs } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { Organisations, OrganisationUserTDO } from '../types/organisations'
import { functions } from '../utils/firebase'
import { organisationsCollection } from './firebase/collections'

class OrganisationService {
  getOrganisations = async (): Promise<Organisations[]> => {
    const ref = await getDocs(organisationsCollection)

    return ref.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }))
  }

  createOrganisationUser = async (
    data: Omit<OrganisationUserTDO, 'expiresAt'> & { expiresAt: Number }
  ) => {
    const createOrganisationUser = httpsCallable(functions, 'createOrgUserV2')
    return await createOrganisationUser(data)
  }
}

const organisationService = new OrganisationService()

export default organisationService
