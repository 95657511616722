import clsx from 'clsx'
import { FieldProps, getIn } from 'formik'
import React from 'react'

const FormikTextarea: React.ComponentType<FieldProps & { label: string }> = ({
  field,
  form,
  label,
  ...rest
}) => {
  const error = getIn(form.errors, field.name)
  const isTouched = getIn(form.touched, field.name)
  const hasError = isTouched && error

  return (
    <div>
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-skin-label print:text-gray-800 print:text-lg"
      >
        {label}
      </label>
      <div
        className={clsx({
          'input mt-1 relative print:border-none print:shadow-none print:outline-none ':
            true,
          'input-error': hasError,
        })}
      >
        <textarea
          id={field.name}
          className={clsx({
            'sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-50 disabled:text-gray-400 h-20 print:hidden':
              true,
            'text-red-900 placeholder-red-300': hasError,
          })}
          {...field}
          {...rest}
        />
        <p className="hidden print:block text-lg text-gray-500">
          {field.value}
        </p>
      </div>
      {hasError ? (
        <p
          className="mt-1 text-xs text-skin-input-error"
          id={`${field.name}-error`}
        >
          {error}
        </p>
      ) : null}
    </div>
  )
}

export default FormikTextarea
