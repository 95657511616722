import React, { useRef } from 'react'
import toast from 'react-hot-toast'

type DropZoneProps = {
  onChange: (file: FileList) => void
}

const DropZone: React.FC<DropZoneProps> = ({ onChange }) => {
  const ref = useRef<HTMLInputElement>(null)
  return (
    <div className="group flex items-center justify-center relative">
      <input
        ref={ref}
        className="absolute top-0 left-0 z-20 h-full w-full cursor-pointer opacity-0 "
        type="file"
        value={undefined}
        accept=".csv"
        onChange={e => {
          if (e.target.files) {
            if (e.target.files[0].type !== 'text/csv') {
              toast.error('Only CSV files are accepted.')
              return
            }
            onChange(e.target.files)
          }
        }}
      />

      <div className="'mt-1 w-full sm:col-span-2 sm:mt-0'">
        <div className="flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {ref &&
              ref.current &&
              ref.current.files &&
              ref.current.files.length > 0 && (
                <p className="font-semibold text-orange-500">
                  {ref.current.files[0].name}
                </p>
              )}
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer  rounded-md font-medium text-brand-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-brand-500 focus-within:ring-offset-2 hover:text-brand-500"
              >
                <span className="text-purple-700">Click to upload</span>
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">CSV files only</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DropZone
