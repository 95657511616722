import React from 'react'
import { Link } from 'react-router-dom'
// import BG from '../assets/login-bg.png'

type SplitAuthLayoutProps = {
  children?: React.ReactNode
}

const SplitAuthLayout: React.FC = ({ children }: SplitAuthLayoutProps) => {
  return (
    <div className="min-h-full flex max-h-screen">
      <div className="flex-1 grid grid-col-3 justify-center  overflow-x-scroll">
        <div className="mt-20" />
        <div className="mx-auto w-full max-w-sm lg:w-96">{children}</div>
        <div className="flex justify-center items-end pb-4 mt-10">
          <Link
            className="text-semibold text-gray-400 mr-6"
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
          <Link
            className="text-semibold text-gray-400"
            to="/terms-and-conditions"
          >
            Terms & Conditions
          </Link>
        </div>
      </div>
      <div className="hidden lg:flex flex-col relative w-0 flex-1 bg-orange-500 justify-center items-center">
        <h2 className="page-tile max-w-md text-center mb-10">
          Placing creativity at the heart of education
        </h2>
        <img src="/img/kids.png" alt="login-bg" />
      </div>
    </div>
  )
}

export default SplitAuthLayout
