import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import groupService from '../services/groupService'
import { Group } from '../types/groups'
import { queryKeys } from '../utils/config'

export function useGroup() {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: [queryKeys.groups.group, id],
    queryFn: async () => await groupService.getGroup(id),
    enabled: !!id,
    placeholderData: () => {
      const groups = queryClient.getQueryData<Group[]>(['groups'])

      if (!groups) return undefined

      return groups.find(group => group.id === id)
    },
  })
}
