import React from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar'

type FrontPageLayoutProps = {
  children?: React.ReactNode
}

const FrontPageLayout: React.FC = ({ children }: FrontPageLayoutProps) => (
  <div className="min-h-screen  flex flex-col justify-between">
    <div className="bg-white h-full">
      <div className="px-4 sm:px-6 lg:px-8">
        <Navbar />
      </div>
      {children}
    </div>
    <Footer />
  </div>
)

export default FrontPageLayout
