import clsx from 'clsx'
import React from 'react'
import { Rubric } from '../../types/qualifications'
import { markingAreasType } from '../../utils/config'
import { displayEvaluation, displayIcon } from '../../utils/markUtils'

type MarkDescriptionProps = {
  rubrics: Rubric[]
  mark: markingAreasType
  value?: number | null
  active?: boolean
}

const handleName = (name: string) => {
  switch (name) {
    case 'overall':
      return 'Overall'
    case 'research':
      return 'Research'
    case 'concepts':
      return 'Concepts'
    case 'failAndFix':
      return 'Fail & Fix'
    case 'communicate':
      return 'Communicate'
    case 'reflect':
      return 'Reflect'

    default:
      return ''
  }
}

const MarkDescriptionPrint: React.FC<MarkDescriptionProps> = ({
  mark,
  rubrics,
  value,
  active,
}) => {
  if (value === null) return null
  const rubric = displayEvaluation(rubrics, mark, value)
  return (
    <div
      className={clsx({
        ' min-h-[184px] print:border-0 print:shadow-none print:bg-transparent':
          true,
        'transition-colors duration-500 border': active,
        'border-orange-500': mark === markingAreasType.research,
        'border-yellow-500': mark === markingAreasType.concepts,
        'border-green-500': mark === markingAreasType.failAndFix,
        'border-blue-500': mark === markingAreasType.communicate,
        'border-pink-500': mark === markingAreasType.reflect,
        '': mark === markingAreasType.overall,
      })}
    >
      <div className="">
        <div className="inline-block align-top mr-3">
          {' '}
          {displayIcon({ active: true, check: mark })}
        </div>
        <div className="inline-block align-top -mt-3">
          <p className="font-medium print:text-lg">
            {value}
            <span className="text-md font-bold ml-1">{rubric.evaluation}</span>
          </p>
          <p className="font-medium print:text-lg">{handleName(mark)}</p>
        </div>
      </div>
      <p
        className={clsx({
          'text-gray-500 font-normal print:text-lg mt-3': true,
          'text-gray-800': mark === markingAreasType.overall,
        })}
      >
        {rubric.description}
      </p>
    </div>
  )
}

export default MarkDescriptionPrint
