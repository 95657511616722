import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { Group, GroupTDO, StudentBasic } from '../types/groups'
import { auth, firestore, functions } from '../utils/firebase'
import { groupCollection, projectCollection } from './firebase/collections'
import { httpsCallable } from 'firebase/functions'

interface BasicWithId extends StudentBasic {
  studentId: string
}

class GroupService {
  createGroup = async ({
    name,
    students,
    userId,
  }: Pick<GroupTDO, 'name' | 'students'> & { userId: string }) => {
    const groupRef = doc<Omit<Group, 'id' | 'students'>, any>(groupCollection)
    const studentsRef = collection(groupRef, 'students')

    await setDoc(groupRef, {
      projectNumber: 0,
      name,
      userId,
      createdAt: serverTimestamp(),
      isArchived: false,
    })

    const batch = writeBatch(firestore)

    students.map(({ studentId, ...rest }) => batch.set(doc(studentsRef), rest))

    await batch.commit()
  }

  updateGroup = async ({
    name,
    students: newStudents,
    groupId,
    isArchived,
  }: Pick<GroupTDO, 'name' | 'students'> & {
    groupId: string
    isArchived: boolean
  }) => {
    const authUser = auth.currentUser
    if (!authUser) {
      throw new Error(
        'User needs to be authenticated in order to perform this operation!'
      )
    }

    const ref = doc(groupCollection, groupId)

    const studentsRef = collection(ref, 'students')

    const currentStudents = await this.getStudentsGroup(groupId)

    const batch = writeBatch(firestore)

    // Batch all removed students
    const studentsToRemove = currentStudents.filter(({ studentId: id1 }) => {
      const doesStudentStillExist = newStudents.some(
        ({ studentId: id2 }) => id2 === id1
      )

      if (!doesStudentStillExist) {
        // the student was removed
        return true
      } else {
        // the student wasn't removed
        return false
      }
    })
    studentsToRemove.forEach(({ studentId }) => {
      batch.delete(doc(studentsRef, studentId))
    })

    // Batch all new students
    newStudents.forEach(student => {
      const isNewStudent = !student.studentId

      if (isNewStudent) {
        batch.set(doc(studentsRef), student)
      }
    })

    await batch.commit()

    // Handle archiving projects if archived is set to true on the group
    const groupSnapshot = await getDoc(ref)

    const isSettingArchivedToTrue =
      isArchived && !groupSnapshot.data()?.isArchived

    if (isSettingArchivedToTrue) {
      const projectsBatch = writeBatch(firestore)

      const projectsQuery = query(
        projectCollection,
        where('userId', '==', authUser.uid),
        where('group', '==', groupId)
      )

      const projectsSnapshot = await getDocs(projectsQuery)

      projectsSnapshot.forEach(async project => {
        if (!project.exists()) {
          return
        }
        projectsBatch.update(project.ref, { isArchived: true })
      })

      await projectsBatch.commit()

      await updateDoc(ref, { projectNumber: 0 })
    }

    await updateDoc(ref, { name, isArchived })
  }

  deleteGroup = async (groupId: string) => {
    const removeGroup = httpsCallable(functions, 'removeGroupV2')

    return await removeGroup({ groupId })
  }

  handleProjectNumber = async ({
    groupId,
    action,
  }: {
    groupId: string
    action: 'sum' | 'subtract'
  }) => {
    const ref = doc(groupCollection, groupId)

    await updateDoc(ref, {
      projectNumber: increment(action === 'sum' ? 1 : -1),
    })
  }

  getGroups = async (userId?: string): Promise<Group[]> => {
    const q = query(
      groupCollection,
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    )
    const ref = await getDocs(q)

    return Promise.all(
      ref.docs.map(async doc => {
        const studentsRef = collection(groupCollection, doc.id, 'students')
        const studentDocs = await getDocs(studentsRef)

        const students = studentDocs.docs.map(student => ({
          name: student.data().name,
          email: student.data().email,
          studentId: student.id,
        }))

        return {
          ...doc.data(),
          id: doc.id,
          students,
        }
      })
    )
  }

  getGroup = async (id?: string): Promise<Group | undefined> => {
    if (!id) return undefined
    const result = await getDoc(doc(groupCollection, id))

    if (!result.exists()) return undefined

    const students = await this.getStudentsGroup(result.id)

    return {
      ...result.data(),
      id: result.id,
      students,
    }
  }

  getStudentsGroup = async (id: string) => {
    const studentsRef = collection(groupCollection, id, 'students')
    const studentDocs = await getDocs(studentsRef)
    const students: BasicWithId[] = []

    studentDocs.docs.map(student => {
      if (student.exists()) {
        const { name, email } = student.data()
        students.push({
          name: name,
          email: email,
          studentId: student.id,
        })
      }

      return null
    })

    return students
  }
}

const groupService = new GroupService()

export default groupService
