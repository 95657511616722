import { FastField, Field } from 'formik'
import React from 'react'
import FormikInput from '../FormikInput'
import FormikSelect from '../FormikSelect'
import Spinner from '../Spinner'

type OrganisationUserFormProps = {
  isSubmitting: boolean
  organisationOptions: {
    value: string
    label: string
  }[]
}

const OrganisationUserForm: React.FC<OrganisationUserFormProps> = ({
  isSubmitting,
  organisationOptions,
}) => {
  return (
    <>
      <fieldset disabled={isSubmitting} className="space-y-3">
        <FastField
          type="text"
          name="firstName"
          component={FormikInput}
          label="First Name"
          placeholder="Their first name"
          autoFocus
        />
      </fieldset>

      <fieldset disabled={isSubmitting} className="space-y-3">
        <FastField
          type="text"
          name="lastName"
          component={FormikInput}
          label="Last Name"
          placeholder="Their last name"
        />
      </fieldset>

      <fieldset disabled={isSubmitting} className="space-y-3">
        <FastField
          type="email"
          name="email"
          component={FormikInput}
          label="Email"
          placeholder="Their email"
        />
      </fieldset>

      <fieldset disabled={isSubmitting} className="space-y-3">
        <Field
          name="organisationId"
          component={FormikSelect}
          label="Organisation"
          options={organisationOptions}
          placeholder="Select Organisation"
          isDisabled={isSubmitting}
        />
      </fieldset>

      <fieldset disabled={isSubmitting} className="space-y-3">
        <FastField
          type="date"
          name="expiresAt"
          component={FormikInput}
          label="Expiry Date"
        />
      </fieldset>

      <div>
        <button
          type="submit"
          className="btn btn-primary btn-full"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner className="h-4 w-4 trailing-icon" />
          ) : (
            'Add User'
          )}
        </button>
      </div>
    </>
  )
}

export default OrganisationUserForm
