import { faUserCog } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { useUser } from '../hooks/useUser'
import authService from '../services/authService'
import { userRoles } from '../utils/config'
import Logo from './icons/Logo'

type NavigationPaths = {
  name: string
  href: string
  role?: userRoles.admin | userRoles.endUser
}

const navigation: NavigationPaths[] = [
  { name: 'My Projects', href: '/projects' },
  { name: 'My Groups', href: '/groups' },
  { name: 'Add User', href: '/admin/add-user', role: userRoles.admin },
]

const frontPageNavigation: NavigationPaths[] = [
  { name: 'Benefits', href: '#benefits' },
  { name: 'Features', href: '#features' },
  { name: 'FAQs', href: '#faqs' },
  { name: 'Contact', href: '#contact' },
]

const Navbar = () => {
  const user = useUser()
  const location = useLocation()
  const navList = location.pathname === '/' ? frontPageNavigation : navigation

  const displayLinks = () => {
    return navList.map(link => {
      if (link.role && link.role !== user.data?.role) return null
      if (location.pathname === '/') {
        return (
          <HashLink
            smooth
            key={link.name}
            to={link.href}
            className="text-base font-medium text-gray-500 hover:text-purple-700"
          >
            {link.name}
          </HashLink>
        )
      }

      if (!user.data) return null

      return (
        <NavLink
          key={link.name}
          to={link.href}
          className={({ isActive }) =>
            clsx({
              'text-base font-medium hover:text-purple-700': true,
              'text-gray-500': !isActive,
              'text-purple-700': isActive,
            })
          }
        >
          {link.name}
        </NavLink>
      )
    })
  }

  return (
    <header className="border-b border-gray-100 bg-white px-3 -mx-4 sm:-mx-6 lg:-mx-8 print:hidden">
      <nav className="container mx-auto" aria-label="Top">
        <div className="w-full py-4 flex items-center justify-between border-b border-gray-100 lg:border-none">
          <div className="flex items-center">
            <Link to={user.data ? '/projects' : '/'}>
              <span className="sr-only">Workflow</span>
              <Logo />
            </Link>
            <div className="hidden ml-10 space-x-8 lg:block">
              {displayLinks()}
            </div>
          </div>
          {user?.data ? (
            <div className="ml-10 space-x-4 flex">
              <Link to="/account" className="btn btn-white">
                <FontAwesomeIcon className="leading-icon" icon={faUserCog} />
                Account
              </Link>
              <button
                onClick={async () => {
                  await authService.logout()
                }}
                className="btn btn-light-purple"
              >
                Sign Out
              </button>
            </div>
          ) : (
            <div className="ml-10 flex items-center">
              <Link to="/auth/sign-in" className="text-link-black mr-7">
                Log in
              </Link>
              <Link to="/auth/register" className="btn btn-primary">
                Sign up
              </Link>
            </div>
          )}
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {displayLinks()}
        </div>
      </nav>
    </header>
  )
}

export default Navbar
