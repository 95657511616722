import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { Student } from '../types/groups'
import { Project, ProjectTDO, StudentMarks } from '../types/projects'
import { markingAreasType, queryKeys } from '../utils/config'
import { auth, firestore, functions } from '../utils/firebase'
import { queryClient } from '../utils/tanstack-react-query'
import { projectCollection } from './firebase/collections'
import groupService from './groupService'
import { httpsCallable } from 'firebase/functions'

class ProjectService {
  createProject = async ({
    communicate,
    concepts,
    failAndFix,
    reflect,
    research,
    ...rest
  }: ProjectTDO & { userId: string }) => {
    const markingAreas: markingAreasType[] = []

    if (communicate) markingAreas.push(markingAreasType.communicate)
    if (concepts) markingAreas.push(markingAreasType.concepts)
    if (failAndFix) markingAreas.push(markingAreasType.failAndFix)
    if (reflect) markingAreas.push(markingAreasType.reflect)
    if (research) markingAreas.push(markingAreasType.research)

    const group = await groupService.getGroup(rest.group)

    if (!group || !group.students) {
      throw new Error(
        'Something went wrong, please try again, if the issues persist contact support.'
      )
    }

    const students = group.students.map(student => ({
      ...student,
      communicate: communicate ? 0 : null,
      concepts: concepts ? 0 : null,
      failAndFix: failAndFix ? 0 : null,
      reflect: reflect ? 0 : null,
      research: research ? 0 : null,
      feedback: '',
      overall: 0,
      group: group.id,
    }))

    const groupRef = doc<Omit<Project, 'id' | 'students' | 'groupName'>, any>(
      projectCollection
    )
    const studentsRef = collection(groupRef, 'students')
    const batch = writeBatch(firestore)
    students.map(student => batch.set(doc(studentsRef), student))
    await batch.commit()

    await setDoc(groupRef, {
      ...rest,
      markingAreas,
      isArchived: false,
      createdAt: serverTimestamp(),
    })

    await groupService.handleProjectNumber({ groupId: group.id, action: 'sum' })
    queryClient.invalidateQueries({ queryKey: [queryKeys.groups.groups] })
  }

  updateProject = async ({
    communicate,
    concepts,
    failAndFix,
    reflect,
    research,
    projectId,
    ...rest
  }: Partial<ProjectTDO> & {
    projectId: string
    isNoEditMarkingAreas?: boolean
  }) => {
    if (rest.isNoEditMarkingAreas) {
      const ref = await doc(projectCollection, projectId)
      await updateDoc(ref, {
        ...rest,
      })
      return
    }
    const markingAreas: markingAreasType[] = []

    // this should never happen
    if (!rest.group) {
      throw new Error(
        'Something went wrong, please try again, if the issues persist contact support.'
      )
    }

    if (communicate) markingAreas.push(markingAreasType.communicate)
    if (concepts) markingAreas.push(markingAreasType.concepts)
    if (failAndFix) markingAreas.push(markingAreasType.failAndFix)
    if (reflect) markingAreas.push(markingAreasType.reflect)
    if (research) markingAreas.push(markingAreasType.research)

    const ref = await doc(projectCollection, projectId)
    const project = (await getDoc(ref)).data()
    const isEditingArchived = project?.isArchived !== rest.isArchived

    if (isEditingArchived && rest.isArchived === true) {
      groupService.handleProjectNumber({
        groupId: rest.group,
        action: 'subtract',
      })
    }
    if (isEditingArchived && rest.isArchived === false) {
      groupService.handleProjectNumber({ groupId: rest.group, action: 'sum' })
    }

    await updateDoc(ref, {
      ...rest,
      markingAreas,
    })
  }

  deleteProject = async (projectId: string) => {
    const removeProject = httpsCallable(functions, 'removeProjectV2')

    return await removeProject({ projectId })
  }

  getProjects = async (userId?: string): Promise<Project[] | undefined> => {
    const q = query(projectCollection, where('userId', '==', userId))
    const ref = await getDocs(q)

    return Promise.all(
      ref.docs.map(async doc => {
        const data = doc.data()

        const students = await this.getProjectStudents(doc.id)
        const group = await groupService.getGroup(data.group)

        return {
          ...doc.data(),
          groupName: group?.name || '',
          students,
          id: doc.id,
        }
      })
    )
  }

  getProjectsForGroup = async (
    groupId?: string
  ): Promise<Project[] | undefined> => {
    const userId = auth.currentUser?.uid
    const q = query(
      projectCollection,
      where('group', '==', groupId),
      where('userId', '==', userId)
    )
    const ref = await getDocs(q)

    return Promise.all(
      ref.docs.map(async doc => {
        const data = doc.data()

        const students = await this.getProjectStudents(doc.id)
        const group = await groupService.getGroup(data.group)

        return {
          ...doc.data(),
          groupName: group?.name || '',
          students,
          id: doc.id,
        }
      })
    )
  }

  getProject = async (id?: string): Promise<Project | undefined> => {
    const result = await getDoc(doc(projectCollection, id))

    if (!result.exists()) return undefined
    const data = result.data()
    const group = await groupService.getGroup(data.group)

    const students = await this.getProjectStudents(result.id)

    return {
      ...result.data(),
      groupName: group?.name || '',
      students: students.map(student => ({
        ...student,
      })),
      id: result.id,
    }
  }

  getProjectStudents = async (id: string): Promise<Student[]> => {
    const studentsRef = collection(projectCollection, id, 'students')
    const studentDocs = await getDocs(studentsRef)
    const students: Student[] = []

    studentDocs.docs.map(student => {
      if (student.exists()) {
        const data = student.data() as Student
        students.push({
          ...data,
          studentId: student.id,
        })
      }

      return null
    })

    return students
  }

  addStudent = async ({
    projectId,
    student,
  }: {
    projectId: string
    student: Student
  }) => {
    const studentsRef = collection(projectCollection, projectId, 'students')
    await addDoc(studentsRef, student)

    // mark students as edited
    await this.updateProject({
      projectId,
      haveStudentsBeenEdited: true,
      isNoEditMarkingAreas: true,
    })
  }

  removeStudent = async ({
    projectId,
    student,
  }: {
    projectId: string
    student: Student
  }) => {
    if (student.studentId) {
      const studentsRef = collection(projectCollection, projectId, 'students')

      await deleteDoc(doc(studentsRef, student.studentId))

      // mark students as edited
      await this.updateProject({
        projectId,
        haveStudentsBeenEdited: true,
        isNoEditMarkingAreas: true,
      })
    }
  }

  updateStudent = async ({
    projectId,
    studentId,
    marks,
  }: {
    projectId: string
    studentId: string
    marks: StudentMarks
  }) => {
    const studentsRef = collection(projectCollection, projectId, 'students')
    const studentDoc = doc(studentsRef, studentId)
    await updateDoc(studentDoc, marks)
  }

  updateStudentFeedback = async ({
    projectId,
    studentId,
    feedback,
  }: {
    projectId: string
    studentId: string
    feedback: string
  }) => {
    const studentsRef = collection(projectCollection, projectId, 'students')
    const studentDoc = doc(studentsRef, studentId)
    await updateDoc(studentDoc, { feedback })
  }
}

const projectService = new ProjectService()

export default projectService
