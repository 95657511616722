import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import Features from '../components/FrontPage/Features'
import GetInTouch from '../components/FrontPage/GetInTouch'
import ReadyToMark from '../components/FrontPage/ReadyToMark'
import Support from '../components/FrontPage/Support'

const FrontPage = () => (
  <div>
    <div className="bg-white min-h-screen px-4 sm:px-6 lg:px-8">
      <div className="bg-orange-500 pt-24 py-72 -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="container mx-auto flex justify-between px-4 md:px-0">
          <div className="max-w-2xl">
            <h1 className="text-5xl font-semibold leading-snug mb-6">
              The assessment tool that rewards creativity
            </h1>
            <p className="mb-12">
              Stellar is a quick, simple and transparent way to assess challenge
              based learning. It trusts the teacher and rewards bravery and true
              creative problem solving.
            </p>
            <Link to="/auth/sign-in" className="btn btn-primary">
              Get Started
            </Link>
          </div>
          <img
            src="/img/brua_hero.png"
            alt="class"
            className="max-h-[350px] hidden md:block"
          />
        </div>
      </div>

      <motion.div
        className="aspect-w-16 aspect-h-10 md:aspect-h-8 lg:aspect-h-6 2xl:aspect-h-5 max-w-5xl -mt-52 mx-4 sm:mx-8 lg:mx-auto mb-24"
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        {/* <iframe
          src="https://player.vimeo.com/video/324944620?h=991dfdab40"
          title="Vimeo video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="rounded-xl overflow-hidden"
        /> */}
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/H1t3aAPR6Ss?controls=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </motion.div>

      <div className="container mx-auto flex flex-col justify-between px-4 md:px-0">
        <ReadyToMark />

        <Features />

        <Support />

        <GetInTouch />
      </div>
    </div>
  </div>
)

export default FrontPage
