import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import LogoSmall from './icons/LogoSmall'

const Footer = () => (
  <footer className="flex flex-col md:flex-row bg-gray-50 p-12 justify-between items-start md:items-center gap-6 md:gap-0">
    <LogoSmall />
    <div className="flex gap-6 md:gap-16 flex-col md:flex-row">
      <Link to="/privacy-policy">Privacy Policy</Link>

      <div className="flex gap-4">
        <a
          href="https://www.instagram.com/daydream.believers/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            className="flex w-4 h-4 text-gray-500"
            //@ts-ignore
            icon={faInstagram}
          />
        </a>
        <a
          href="https://twitter.com/be_daydream"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            className="flex w-4 h-4 text-gray-500"
            //@ts-ignore
            icon={faTwitter}
          />
        </a>
        <a
          href="https://www.linkedin.com/company/daydreambelievers"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            className="flex w-4 h-4 text-gray-500"
            //@ts-ignore
            icon={faLinkedin}
          />
        </a>
        <a
          href="https://www.facebook.com/daydreamingbelievers"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            className="flex w-4 h-4 text-gray-500"
            //@ts-ignore
            icon={faFacebook}
          />
        </a>
      </div>

      <p>
        © {new Date().getFullYear()} Daydream Believers Education LTD. All
        rights reserved.
      </p>
    </div>
  </footer>
)

export default Footer
