import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import LoadingScreen from '../components/LoadingScreen'
import subscriptionService from '../services/subscriptionService'
import { queryKeys } from '../utils/config'
import getStripe from '../utils/getStripe'
import { queryClient } from '../utils/tanstack-react-query'
import { useEffect } from 'react'

const Subscribe = () => {
  const { sessionId } = useParams<{ sessionId?: string }>()

  const {
    isLoading,
    error,
    data: session,
    isSuccess,
  } = useQuery<any, any, { data: { sessionId: string } }>({
    queryKey: [queryKeys.stripe.session, sessionId],
    queryFn: subscriptionService.createStripeSession,
  })

  useEffect(() => {
    if (!isSuccess) {
      return
    }
    ;(async () => {
      const stripe = await getStripe()

      if (!stripe) {
        throw new Error('Stripe is not loaded')
      }

      queryClient.invalidateQueries({
        queryKey: [queryKeys.stripe.mySubscription],
      })

      await stripe.redirectToCheckout({
        sessionId: session.data.sessionId,
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  if (isLoading) return <LoadingScreen />

  if (error) {
    return <p>{error.message}</p>
  }

  return <></>
}

export default Subscribe
