import { Faqs } from '../../types/front-page'
import { Group } from '../../types/groups'
import { Organisations } from '../../types/organisations'
import { Project } from '../../types/projects'
import { Qualification } from '../../types/qualifications'
import { FBSubscription } from '../../types/subscriptions'
import { User } from '../../types/user'
import { createCollection } from '../../utils/firebase'

export const usersCollection = createCollection<User>('users')
export const subscriptionCollection =
  createCollection<FBSubscription>('subscriptions')
export const groupCollection = createCollection<Omit<Group, 'id'>>('groups')
export const projectCollection =
  createCollection<Omit<Project, 'id'>>('projects')
export const qualificationCollection =
  createCollection<Omit<Qualification, 'id'>>('qualifications')
export const faqsCollection = createCollection<Omit<Faqs, 'id'>>('faqs')
export const organisationsCollection =
  createCollection<Omit<Organisations, 'id'>>('organisations')
