import { FieldProps, getIn } from 'formik'
import React from 'react'

const FormikCheckbox: React.ComponentType<
  FieldProps & { label: string; description: string }
> = ({ field, form, label, description, ...rest }) => {
  const error = getIn(form.errors, field.name)
  const isTouched = getIn(form.touched, field.name)
  const hasError = isTouched && error

  return (
    <div>
      <div className="flex flex-row-reverse gap-3">
        <label htmlFor={field.name}>
          <p className="text-md font-medium text-skin-label cursor-pointer">
            {label}
          </p>
          <p className="text-md font-normal text-gray-500 cursor-pointer">
            {description}
          </p>
        </label>
        <div className="ml-auto flex items-center h-5">
          <input
            id={field.name}
            style={{ boxShadow: 'none' }}
            className="form-checkbox h-4 w-4 text-secondary-700 border-gray-300 rounded cursor-pointer focus:ring-0"
            type="checkbox"
            {...field}
            {...rest}
          />
        </div>
      </div>
      {hasError ? (
        <p
          className="mt-1 text-xs text-skin-input-error"
          id={`${field.name}-error`}
        >
          {error}
        </p>
      ) : null}
    </div>
  )
}

export default FormikCheckbox
