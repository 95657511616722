import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { FieldProps, getIn } from 'formik'
import React from 'react'

const FormikInput: React.ComponentType<
  FieldProps & { label: string; type: string }
> = ({ field, form, label, ...rest }) => {
  const error = getIn(form.errors, field.name)
  const isTouched = getIn(form.touched, field.name)
  const hasError = isTouched && error

  return (
    <div>
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-skin-label"
      >
        {label}
      </label>
      <div
        className={clsx({
          'input relative': true,
          'input-error bg-white': hasError,
          'mt-1': label,
        })}
      >
        {rest.type === 'search' && (
          <div
            className={clsx({
              'ml-3 flex items-center': true,
              'text-red-400': hasError,
            })}
          >
            <FontAwesomeIcon className="leading-icon" icon={faSearch} />
          </div>
        )}

        <input
          id={field.name}
          className={clsx({
            'block w-full appearance-none rounded-md border-0 bg-white px-3 py-2 placeholder-gray-400 focus:outline-none disabled:bg-gray-50 disabled:text-gray-400 sm:text-sm':
              true,
            'text-red-900  placeholder-red-300 ': hasError,
          })}
          {...field}
          {...rest}
        />
        {hasError ? (
          <div className="pointer-events-none inset-y-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {hasError ? (
        <p
          className="mt-1 text-xs text-skin-input-error"
          id={`${field.name}-error`}
        >
          {error}
        </p>
      ) : null}
    </div>
  )
}

export default FormikInput
