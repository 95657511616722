import { FastField, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import * as Yup from 'yup'
import frontPageService from '../../services/frontPageService'
import { GetInTouchTDO } from '../../types/front-page'
import FormikCheckbox from '../FormikCheckbox'
import FormikInput from '../FormikInput'
import FormikTextarea from '../FormikTextarea'

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  email: Yup.string().required(),
  message: Yup.string().required(),
  agreement: Yup.bool()
    .required()
    .isTrue('You must agree to our privacy policy.'),
})

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
  agreement: false,
}

const GetInTouch = () => {
  const submitContactUsForm = useMutation({
    mutationFn: frontPageService.submitContactUsForm,
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
    onSuccess: () => {
      toast.success('Form sent successfully.')
    },
  })

  const handleOnSubmit = async (
    { agreement, ...rest }: GetInTouchTDO,
    formikBag: FormikHelpers<GetInTouchTDO>
  ) => {
    try {
      await submitContactUsForm.mutateAsync(rest)
      formikBag.resetForm()
    } catch (error) {
      console.log(
        '🚀 ~ file: GetInTouch.tsx ~ line 42 ~ handleOnSubmit ~ error',
        error
      )
    }
  }

  return (
    <section
      className="flex flex-col md:flex-row bg-purple-50 lg:h-[700px] mb-24  -mx-8 sm:-mx-0 md:rounded-lg overflow-hidden"
      id="contact"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, values }: FormikProps<GetInTouchTDO>) => {
          return (
            <Form className="flex-1 py-12 md:py-24 px-6 md:px-12 flex flex-col ">
              <h2 className="landing-title-section mb-5 text-gray-800">
                Get in touch
              </h2>
              <p className="text-lg mb-12 text-gray-800">
                Our friendly team would love to hear from you.
              </p>
              <div className="flex gap-6 mb-6 flex-col md:flex-row">
                <fieldset className="space-y-3 w-full" disabled={isSubmitting}>
                  <FastField
                    type="text"
                    name="firstName"
                    component={FormikInput}
                    label="First name"
                    placeholder="First name"
                  />
                </fieldset>
                <fieldset className="space-y-3 w-full" disabled={isSubmitting}>
                  <FastField
                    type="text"
                    name="lastName"
                    component={FormikInput}
                    label="Last name"
                    placeholder="Last name"
                  />
                </fieldset>
              </div>

              <fieldset className="space-y-3 mb-6" disabled={isSubmitting}>
                <FastField
                  type="email"
                  name="email"
                  component={FormikInput}
                  label="Email"
                  placeholder="your@company.com"
                />
              </fieldset>
              <fieldset className="space-y-3 mb-6" disabled={isSubmitting}>
                <FastField
                  type="text"
                  name="message"
                  component={FormikTextarea}
                  label="Message"
                />
              </fieldset>

              <fieldset
                className="space-y-3 mb-6 self-start"
                disabled={isSubmitting}
              >
                <FastField
                  name="agreement"
                  component={FormikCheckbox}
                  label="You agree to our friendly privacy policy."
                  checked={values.agreement}
                />
              </fieldset>
              <button
                type="submit"
                className="btn btn-dark-purple btn-full"
                disabled={isSubmitting || !values.agreement}
              >
                Send Message
              </button>
            </Form>
          )
        }}
      </Formik>
      <div className="flex-1 h-full hidden lg:flex justify-center items-center">
        <img
          src="/img/brua_contact.png"
          alt="cup"
          className="object-cover max-h-[500px]"
        />
      </div>
    </section>
  )
}

export default GetInTouch
