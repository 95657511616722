import { faCircle, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CheckCircleIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@tanstack/react-query'
import subscriptionService from '../../services/subscriptionService'
import { queryKeys } from '../../utils/config'
import getStripe from '../../utils/getStripe'
import { queryClient as tanstackQueryClient } from '../../utils/tanstack-react-query'
import ErrorPage from '../ErrorPage'

const PaymentCards = () => {
  const [toggleAddCard, setToggleAddCard] = useState(false)
  const {
    data: cards,
    isLoading: isCardsLoading,
    isSuccess: isCardsSuccess,
    isError: isCardsError,
  } = useQuery({
    queryKey: [queryKeys.stripe.cards],
    queryFn: subscriptionService.getPaymentMethods,
    select: response => response.data,
  })

  const setStripeDefaultPayment = useMutation({
    mutationFn: subscriptionService.setStripeDefaultPayment,
    onError: () => {
      toast.error(
        'Something went wrong, if the error persist please contact support.'
      )
    },
    onSuccess: async () => {
      await tanstackQueryClient.invalidateQueries({
        queryKey: [queryKeys.stripe.cards],
      })
      toast.success('Default Card Updated.')
    },
  })

  const {
    isLoading: isSetupLoading,
    error: setupError,
    isSuccess: isSetupSuccess,
    data: stripeSession,
  } = useQuery<any, any, { data: string }>({
    queryKey: [queryKeys.stripe.session],
    queryFn: subscriptionService.createStripeSetup,
    enabled: toggleAddCard,
  })

  useEffect(() => {
    if (!isSetupSuccess) {
      return
    }
    ;(async () => {
      setToggleAddCard(false)
      const stripe = await getStripe()

      if (!stripe) {
        throw new Error('Stripe is not loaded')
      }

      await stripe.redirectToCheckout({
        sessionId: stripeSession.data,
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetupSuccess])

  if (isCardsLoading || setStripeDefaultPayment.isPending) {
    return (
      <div className="flex flex-col gap-3 justify-start mb-4">
        <div className="card p-4 shadow-none border relative w-full flex flex-row gap-3 justify-start h-[100px] border-gray-300 bg-gray-200 animate-pulse" />
      </div>
    )
  }

  if (setupError) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
      />
    )
  }

  if (!isCardsSuccess || isCardsError) {
    return <p>No cards Found</p>
  }

  return (
    <>
      <div className="flex flex-col gap-3 justify-start mb-4">
        {cards.map(({ id, card, default: isDefault }) => {
          const isExpired =
            card &&
            (card.exp_year < new Date().getFullYear() ||
              (card.exp_month < new Date().getMonth() &&
                card.exp_year < new Date().getFullYear()))
          return (
            <div
              key={id}
              className={clsx({
                'card p-4 shadow-none border relative w-full flex flex-row gap-3 justify-start h-[100px]':
                  true,
                'border-gray-200': !isDefault,
                'border-blue-300 bg-blue-50': isDefault,
              })}
            >
              <div className="absolute right-4 top-4">
                {isDefault ? (
                  <CheckCircleIcon className="w-5 h-5 text-blue-600" />
                ) : (
                  <FontAwesomeIcon
                    className="flex w-4 h-4 text-gray-300"
                    icon={faCircle}
                  />
                )}
              </div>
              <p>
                <img
                  src={`/img/${card?.brand}.png`}
                  alt={card?.brand}
                  className="w-8 mt-1"
                />
              </p>
              <div>
                <p
                  className={clsx({
                    'text-sm font-medium': true,
                    'text-blue-800': isDefault,
                    'text-gray-700': !isDefault,
                  })}
                >
                  <span className="capitalize">{card?.brand}</span> ends in{' '}
                  {card?.last4}
                </p>
                <p
                  className={clsx({
                    'light-text': true,
                    'text-blue-600': isDefault && !isExpired,
                    'text-gray-500': !isDefault && !isExpired,
                    'text-red-500': isExpired,
                  })}
                >
                  {!isExpired
                    ? `Expiry ${card?.exp_month}/${card?.exp_year}`
                    : 'Expired'}
                </p>

                {!isDefault && !isExpired && (
                  <button
                    onClick={async () =>
                      await setStripeDefaultPayment.mutateAsync(id)
                    }
                    className="text-sm font-medium text-link-black mt-2"
                  >
                    Set as default
                  </button>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <button
        className="font-normal text-md text-gray-500 flex items-center disabled:text-gray-300"
        disabled={isSetupLoading}
        onClick={() => setToggleAddCard(true)}
      >
        <FontAwesomeIcon
          className="flex w-4 h-4 text-gray-500 leading-icon"
          icon={faPlus}
        />
        Add new payment method
      </button>
    </>
  )
}

export default PaymentCards
