import {
  faCalculatorAlt,
  faLightbulb,
  faRepeat,
  faSearch,
  faStar,
  faUsersClass,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { StudentMarks } from '../types/projects'
import { Rubric } from '../types/qualifications'
import { markingAreasType } from './config'

type DisplayIconProps = {
  size?: '1x' | '2x'
  active: boolean
  check: string
}

export const displayIcon = ({
  size = '1x',
  check,
  active,
}: DisplayIconProps) => {
  switch (check) {
    case 'research':
      return (
        <div
          className={clsx({
            'mark-icon': true,
            'w-8 h-8': size === '1x',
            'bg-orange-200 text-orange-700': active,
          })}
        >
          <FontAwesomeIcon
            className={clsx({
              'w-4 h-4': size === '1x',
              'w-6 h-6': size === '2x',
            })}
            icon={faSearch}
          />
        </div>
      )
    case 'concepts':
      return (
        <div
          className={clsx({
            'mark-icon ': true,
            'w-8 h-8': size === '1x',
            'bg-yellow-200 text-yellow-700': active,
          })}
        >
          <FontAwesomeIcon
            className={clsx({
              'w-4 h-4': size === '1x',
              'w-6 h-6': size === '2x',
            })}
            icon={faLightbulb}
          />
        </div>
      )
    case 'failAndFix':
      return (
        <div
          className={clsx({
            'mark-icon ': true,
            'w-8 h-8': size === '1x',
            'bg-green-200 text-green-700': active,
          })}
        >
          <FontAwesomeIcon
            className={clsx({
              'w-4 h-4': size === '1x',
              'w-6 h-6': size === '2x',
            })}
            icon={faRepeat}
          />
        </div>
      )
    case 'communicate':
      return (
        <div
          className={clsx({
            'mark-icon ': true,
            'w-8 h-8': size === '1x',
            'bg-blue-200 text-blue-700': active,
          })}
        >
          <FontAwesomeIcon
            className={clsx({
              'w-4 h-4': size === '1x',
              'w-6 h-6': size === '2x',
            })}
            icon={faUsersClass}
          />
        </div>
      )
    case 'reflect':
      return (
        <div
          className={clsx({
            'mark-icon ': true,
            'w-8 h-8': size === '1x',
            'bg-pink-200 text-pink-700': active,
          })}
        >
          <FontAwesomeIcon
            className={clsx({
              'w-4 h-4': size === '1x',
              'w-6 h-6': size === '2x',
            })}
            icon={faStar}
          />
        </div>
      )
    case 'overall':
      return (
        <div
          className={clsx({
            'mark-icon ': true,
            'w-8 h-8': size === '1x',
            'bg-purple-200 text-purple-700': active,
          })}
        >
          <FontAwesomeIcon
            className={clsx({
              'w-4 h-4': size === '1x',
              'w-6 h-6': size === '2x',
            })}
            icon={faCalculatorAlt}
          />
        </div>
      )

    default:
      return (
        <div
          className={clsx({
            'mark-icon ': true,
            'w-8 h-8': size === '1x',
            'bg-orange-200 text-orange-700': active,
          })}
        >
          <FontAwesomeIcon
            className={clsx({
              'w-4 h-4': size === '1x',
              'w-6 h-6': size === '2x',
            })}
            icon={faSearch}
          />
        </div>
      )
  }
}

export const displayEvaluation = (
  rubrics: Rubric[],
  mark: markingAreasType,
  value: number | null | undefined
): Rubric => {
  if (value !== null && value !== undefined) {
    const result = rubrics.filter(
      ({ name, rangeLower, rangeUpper }) =>
        name === mark && rangeLower <= value && value <= rangeUpper
    )[0]

    if (result) {
      return result
    }
  }
  return {} as Rubric
}

export const calculateAverage = (
  markingAreas: Omit<markingAreasType, 'overall'>[],
  marks: Omit<StudentMarks, 'overall'>
) => {
  // @ts-ignore
  const filterMarks = markingAreas.map(mark => marks[mark])
  const sum = filterMarks.reduce((a, b) => (a || 0) + (b || 0), 0)
  return Math.round(sum / markingAreas.length)
}
