import React from 'react'
import Spinner from './Spinner'

const LoadingScreen: React.FC = () => {
  return (
    <div className="text-secondary-600 fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
      <Spinner className="mb-2 h-12 w-12" />
      <h2 className="text-center text-xl font-semibold">Loading...</h2>
    </div>
  )
}

export default LoadingScreen
