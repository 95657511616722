import React from 'react'

const Privacy = () => (
  <div className="container mx-auto flex flex-col justify-between px-4 md:px-0 mt-16">
    <h1 className="page-tile  mb-6">Privacy Policy</h1>
    <p className="text-gray-600 mb-6">
      Daydream Believers Education LTD. know that you care about how your
      Personal Data is used and shared, and we take your privacy seriously. That
      is why we do not collect any personal information about you apart from
      what you provide to us when signing up. We only store your contact details
      to identify you, and remind you if your subscription is coming to an end.
      Any student names, email addresses or grades that you enter into our tool
      are stored securely within your account and will never be shared with
      anyone else, they are only accessible by you, or other teachers you select
      to share a class with. Our server is in Europe West with Firebase and
      Stellar Assessment complies with GDPR and the Data Protection Act.
    </p>

    <p className="text-gray-600 mb-6">That's it!</p>
  </div>
)

export default Privacy
