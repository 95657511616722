import { FastField, Field } from 'formik'
import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import groupService from '../../services/groupService'
import qualificationService from '../../services/qualificationService'
import { ProjectTDO } from '../../types/projects'
import { queryKeys } from '../../utils/config'
import FormikCheckbox from '../FormikCheckbox'
import FormikInput from '../FormikInput'
import FormikSelect from '../FormikSelect'
import Section from '../Sections'
import FormikMarkCheckbox from './FormikMarkCheckbox'
import { compact, orderBy, uniqBy } from 'lodash'

type NewProjectFormProps = {
  isSubmitting: boolean
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  values: ProjectTDO
  haveStudentsBeenEdited?: boolean
}

const NewProjectForm: React.FC<NewProjectFormProps> = ({
  isSubmitting,
  values,
  setFieldValue,
  haveStudentsBeenEdited,
}) => {
  const { id } = useParams<{ id: string }>()
  const user = useUser()

  const { data: groupOptions } = useQuery({
    queryKey: [queryKeys.groups.groupOptions],
    queryFn: () => groupService.getGroups(user.data?.id),
    enabled: !!user.data,
    select: group =>
      group
        .filter(group => !group.isArchived)
        .map(({ name, id }) => {
          return {
            value: id,
            label: id && haveStudentsBeenEdited ? `${name} (Edited)` : name,
          }
        }),
  })

  const { data: qualificationOptions } = useQuery({
    queryKey: [queryKeys.qualifications.qualificationOptions],
    queryFn: () =>
      qualificationService.getQualifications(user.data?.organisation?.id),
    select: group => {
      const options = orderBy(
        group.map(({ name, id }) => ({
          value: id,
          label: name,
        })),
        'label'
      )

      const lvl5CreativeThinking = options.find(
        option => option.label === 'Creative Thinking Level 5'
      )
      const lvl6CreativeThinking = options.find(
        option => option.label === 'Creative Thinking Level 6'
      )
      const solarPunk = options.find(
        option => option.label === 'Solarpunk Island Challenge'
      )
      const marseum = options.find(
        option => option.label === 'Marseum Challenge'
      )
      const seaStory = options.find(
        option => option.label === 'SeaStory Challenge'
      )

      return uniqBy(
        compact([
          lvl6CreativeThinking,
          lvl5CreativeThinking,
          solarPunk,
          marseum,
          seaStory,
          ...options,
        ]),
        'value'
      )
    },
  })

  useEffect(() => {
    if (values.qualification) {
      const findQualification = qualificationOptions?.find(
        ({ value }) => value === values.qualification
      )

      if (findQualification) {
        setFieldValue('qualificationName', findQualification.label)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.qualification])

  return (
    <>
      <Section.Body>
        <Section.Item
          htmlFor="projectName"
          label="Project Details"
          description="Specify project details"
        >
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <fieldset className="space-y-3" disabled={isSubmitting}>
              <FastField
                type="text"
                name="projectName"
                component={FormikInput}
                label="Project Name"
                placeholder="My Great Project"
                autoFocus
              />
            </fieldset>

            <fieldset className="space-y-3" disabled={isSubmitting}>
              <Field
                name="qualification"
                component={FormikSelect}
                label="Qualification"
                options={qualificationOptions}
                placeholder="Select Qualification"
              />
            </fieldset>

            <fieldset className="space-y-3" disabled={isSubmitting}>
              <Field
                name="group"
                component={FormikSelect}
                label="Group"
                options={groupOptions}
                placeholder="Select Group"
                isDisabled={id}
              />
            </fieldset>

            <fieldset className="space-y-3 mt-7" disabled={isSubmitting}>
              <FastField
                name="feedback"
                component={FormikCheckbox}
                label="Enable Custom Feedback"
                description="This gives you a space to include specific written feedback for each student."
                checked={values.feedback}
              />
            </fieldset>
            {/**
             * if there's no id in the url, we are creating a new group
             */}
            {id && (
              <fieldset className="space-y-3 mt-7" disabled={isSubmitting}>
                <FastField
                  name="isArchived"
                  component={FormikCheckbox}
                  label="Archive project"
                  description="Check this box to hide the project from the active projects list."
                  checked={values.isArchived}
                />
              </fieldset>
            )}
          </div>
        </Section.Item>

        <Section.Item
          label="Marking Criteria"
          description="Deselect any learning outcomes you are not assessing in this project."
        >
          <div className="grid grid-cols-3 gap-3 place-content-between mt-6 lg:grid-cols-none lg:grid-flow-col">
            <fieldset className="space-y-3" disabled={isSubmitting}>
              <FastField
                name="research"
                component={FormikMarkCheckbox}
                label="Research"
                checked={values.research}
              />
            </fieldset>

            <fieldset
              className="space-y-3 place-self-center lg:place-self-auto"
              disabled={isSubmitting}
            >
              <FastField
                name="concepts"
                component={FormikMarkCheckbox}
                label="Concepts"
                checked={values.concepts}
              />
            </fieldset>

            <fieldset
              className="space-y-3 place-self-end lg:place-self-auto"
              disabled={isSubmitting}
            >
              <FastField
                name="failAndFix"
                component={FormikMarkCheckbox}
                label="Fail & Fix"
                checked={values.failAndFix}
              />
            </fieldset>

            <fieldset
              className="space-y-3 mt-6 lg:mt-0"
              disabled={isSubmitting}
            >
              <FastField
                name="communicate"
                component={FormikMarkCheckbox}
                label="Communicate"
                checked={values.communicate}
              />
            </fieldset>

            <fieldset
              className="space-y-3 place-self-center mt-6 lg:place-self-auto lg:mt-0"
              disabled={isSubmitting}
            >
              <FastField
                name="reflect"
                component={FormikMarkCheckbox}
                label="Reflect"
                checked={values.reflect}
              />
            </fieldset>
          </div>
        </Section.Item>
      </Section.Body>
    </>
  )
}
export default NewProjectForm
