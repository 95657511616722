import { getDocs } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { Faqs, GetInTouchTDO } from '../types/front-page'
import { functions } from '../utils/firebase'
import { faqsCollection } from './firebase/collections'

class FrontPageService {
  getFaqs = async (): Promise<Faqs[]> => {
    const ref = await getDocs(faqsCollection)

    return ref.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }))
  }
  submitContactUsForm = async (data: Omit<GetInTouchTDO, 'agreement'>) => {
    const contactUs = httpsCallable(functions, 'contactUsV2')
    return await contactUs(data)
  }
}

const frontPageService = new FrontPageService()

export default frontPageService
