import * as React from 'react'

const LogoSmall = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m15.493.047-.736 1.346a.09.09 0 0 1-.036.036l-1.347.737a.09.09 0 0 0 0 .157l1.347.737a.09.09 0 0 1 .036.036l.736 1.347a.09.09 0 0 0 .158 0l.737-1.347a.089.089 0 0 1 .035-.036l1.347-.737a.09.09 0 0 0 0-.157l-1.347-.737a.089.089 0 0 1-.035-.035L15.65.047a.09.09 0 0 0-.158 0ZM23.811 25.624l-.736 1.347a.089.089 0 0 1-.036.035l-1.347.737a.09.09 0 0 0 0 .158l1.347.736c.015.009.027.02.035.036l.737 1.347a.09.09 0 0 0 .158 0l.737-1.347a.09.09 0 0 1 .035-.036l1.347-.736a.09.09 0 0 0 0-.158l-1.347-.737a.09.09 0 0 1-.035-.035l-.737-1.347a.09.09 0 0 0-.158 0Z"
      fill="#FF8039"
    />
    <path
      d="M29.024 12.566a.55.55 0 0 1-.324-.105L17.73 4.497a.554.554 0 0 1 .65-.896l10.97 7.964a.554.554 0 0 1-.326 1.002Z"
      fill="#0F0F35"
    />
    <path
      d="M24.906 25.273a.554.554 0 0 1-.527-.725l4.119-12.706a.555.555 0 0 1 1.053.342l-4.118 12.705a.554.554 0 0 1-.527.384ZM20.506 27.445a.547.547 0 0 1-.147-.02l-10.483-2.88a.554.554 0 1 1 .294-1.067l10.483 2.879a.554.554 0 0 1-.147 1.088Z"
      fill="#0F0F35"
    />
    <path
      d="M10.023 24.565a.553.553 0 0 1-.463-.249L1.656 12.318a.554.554 0 0 1 .925-.61l7.904 11.999a.554.554 0 0 1-.462.858Z"
      fill="#0F0F35"
    />
    <path
      d="M2.119 12.567a.554.554 0 0 1-.326-1.002L12.762 3.6a.554.554 0 0 1 .65.896L2.445 12.46a.553.553 0 0 1-.325.106Z"
      fill="#0F0F35"
    />
    <path
      d="M2.486 14.051a2.038 2.038 0 1 0 0-4.076 2.038 2.038 0 0 0 0 4.076Z"
      fill="#26DA35"
    />
    <path
      d="M28.659 14.041a2.038 2.038 0 1 0 0-4.077 2.038 2.038 0 0 0 0 4.077Z"
      fill="#00D5EC"
    />
    <path
      d="M9.972 26.034a2.038 2.038 0 1 0 0-4.077 2.038 2.038 0 0 0 0 4.077Z"
      fill="#CB6BEA"
    />
    <path
      d="M24.018 1.79a.895.895 0 1 0 0-1.79.895.895 0 0 0 0 1.79ZM4.757 5.52a.895.895 0 1 0 0-1.789.895.895 0 0 0 0 1.79ZM18.82 19.645a.895.895 0 1 0 .001-1.79.895.895 0 0 0 0 1.79ZM13.218 12.62a.447.447 0 1 0 0-.895.447.447 0 0 0 0 .894ZM14.113 32a.895.895 0 1 0 0-1.79.895.895 0 0 0 0 1.79ZM.447 21.881a.447.447 0 1 0 0-.895.447.447 0 0 0 0 .895ZM30.697 19.645a.447.447 0 1 0 0-.895.447.447 0 0 0 0 .895Z"
      fill="#0F0F35"
    />
  </svg>
)

export default LogoSmall
